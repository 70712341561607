import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/login';
import Register from './pages/register';
import Cart from './pages/Cart';
import ProductDetail from './pages/ProductDetail';
import CartVerify from './pages/CartVerify';
import CartComplete from './pages/CartComplete';
import Product from './pages/Product';
import About from './pages/about';
import Contact from './pages/Contact';
import HowtoBuyProd from './pages/HowtoBuyProd';
import Reset from './pages/reset';
import HowToPatment from './pages/HowToPatment';
import Howtodelivery from './pages/Howtodelivery';
import HowtoLogin from './pages/HowtoLogin';
import Resetpassword from './pages/resetpassword';
import Admin from './pages/admin/Admin';
import Promotion from './pages/Promotion';

import ProductMask from './pages/ProductMask';
import ProductMop from './pages/ProductMop';
import ProductGlove from './pages/ProductGlove';
import ProductNeedle from './pages/ProductNeedle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let theme = createTheme({
  palette: {
    primary: {
      main: '#0A3A22',
    },
    secondary: {
      main: '#134C30',

    },
  },
  typography: {
    fontFamily: [
      'Kanit',
      'sans-serif',

    ].join(','),
  },
});

const router = createBrowserRouter([
  
  {
    element: <App />, children: [
      { path: '/', element: <Home /> },
      { path: '/cart', element: <Cart /> },
      { path: '/cart_verify', element: <CartVerify /> },
      { path: '/cart_complete', element: <CartComplete /> },
      { path: '/product_detail/:id', element: <ProductDetail /> },
      { path: '/shop/:id', element: <Product /> },
      { path: '/about', element: <About /> },
      { path: '/contact', element: <Contact /> },
      { path: '/howtobuy', element: <HowtoBuyProd /> },
      { path: '/howtopayment', element: <HowToPatment /> },
      { path: '/howtodelivery', element: <Howtodelivery /> },
      { path: '/howtologin', element: <HowtoLogin /> },
      { path: '/promotion', element: <Promotion /> },
      // PATH
      { path: '/register', element: <Register /> },
      { path: '/login', element: <Login /> },
      { path: '/admin', element: <Admin /> },
      { path: '/reset', element: <Reset /> },
      { path: '/resetpassword/:params', element: <Resetpassword /> },

      //Page Product
      { path: '/shop/mask', element: <ProductMask /> },
      { path: '/shop/mopcap', element: <ProductMop /> },
      { path: '/shop/glove', element: <ProductGlove /> },
      { path: '/shop/needle', element: <ProductNeedle /> },

    ]
  }
])
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
