import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
// import hero from "../image/Home/imghero.png";
import hero from "../image/Home/hero2.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaLine } from "react-icons/fa";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";
import { useOutletContext } from "react-router-dom";

const CardCustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const TypoGraphyHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "0 auto",
  fontWeight: 500,
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));
const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0",
  textIndent: "24px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
  },
}));
const Containervontact = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  paddingBottom: "24px",
}));
function Howtodelivery() {
  const context: any = useOutletContext();
  return (
    <Grid container flexDirection="column" gap="24px">
      <TypoGraphyHead className=" pt-6 md:pt-12">
        วิธีการจัดส่งสินค้า
      </TypoGraphyHead>

      <Grid container sx={{ px: "4%" }}>
        {/* <img className="w-full lg:h-[600px] h-[280px]" src={hero} alt="howto"  /> */}
        <img
          className="pt-5 md:h-[300px] lg:h-[350px] xl:h-[500px] w-full "
          src={context[0].bannerYT.banner.replace(
            "{api_path}",
            process.env.REACT_APP_API_URL
          )}
          alt="hero1"
        />
      </Grid>
      <div className=" md:pb-7"></div>

      <Grid container flexDirection="column" sx={{ px: "4%", pb: "4%" }}>
        {/* <Containervontact> */}
        {/* <Grid container>
          <CardCustomHead>CONTACT US</CardCustomHead>
          </Grid>
          <div className='border border-black relative bottom-16 opacity-5'>

          </div> */}
        <ContactUsNewVer />

        {/* </Containervontact> */}
      </Grid>
    </Grid>
  );
}

export default Howtodelivery;
