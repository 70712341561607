import { Box, Divider, Grid, Typography, styled } from "@mui/material";
import React from "react";
import logo from "../../image/LOGO-04.png";
import shoppee from "../../assets/images/shoppelogo.png";
import lazada from "../../assets/images/lazadalogo.png";
type Props = {};
const CardCustomProd = styled(Box)(({ theme }) => ({
  flexShrink: "0",
  padding: "12px 0",
  fontSize: "24px",
  borderRadius: "16px",
  textAlign: "center",
  margin: "0 auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  userSelect: "none",
  [theme.breakpoints.up("xs")]: {
    width: "140px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "220px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "240px",
  },
}));

const CardCustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "auto",
  paddingRight:"30px",
  paddingLeft:"30px",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));
const Img = styled("img")(({ theme }) => ({}));
const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textIndent: "120px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
    textIndent: "40px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    textIndent: "60px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    textIndent: "80px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
    textIndent: "100px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    textIndent: "120px",
  },
}));
function AboutUs({}: Props) {
  return (
    <Grid className="px-[5%]" container width={"100%"}>
      <Grid className="w-full justify-center items-center">
        <div className="w-full relative items-center flex justify-center my-12">
          <CardCustomHead className=" absolute bg-white">
            ABOUT US
          </CardCustomHead>
          <Divider className=" w-full" />
        </div>
      </Grid>

      <Grid container justifyContent={"center"} className="md:my-6 my-0">
        <Img src={logo} sx={{ width: { md: "50%", xs: "100%" } }} />
      </Grid>
      <Grid className="my-6">
        <TypoGraphyTitle>
          ศูนย์รวมอุปกรณ์สำหรับผู้สูงอายุและอุปกรณ์ทางการแพทย์เพื่อสุขภาพที่หลากหลายพร้อมให้บริการด้วยความเชี่ยวชาญและ
          ความเอาใจใสด้วยคำแนะนำทีถูกต้องและอบอุ่นต่อลูกค้าทุกคน
          เราจึงไม่หยุดพัฒนาบุคลากร รวมถึงการคัดสรรสินค้าที่มีคุณภาพ ราคาเหมาะสม
          เพื่อให้ลูกค้าของเรามั่นใจวางใจ ในผลิตภัณฑ์และการบริการของเรา
        </TypoGraphyTitle>
        <br />
        <TypoGraphyTitle>
          เรามุ่งหวังให้ผู้ป่วยสามารถดำเนินชีวิตได้อย่างมีความสุขและมีสุขภาพที่ดีต่อไป
          ด้วยประสบการณ์กว่า 10 ปี ฟาซิแเคร์คำนึงถึง ความพึงพอใจของลูกค้าทุกท่าน
          ไม่ว่าจะเป็นความสะดวกสบายในการเข้าถึงสินค้า
          ผ่านช่องทางการซื้อที่หลากหลาย มีบริการชำระเงินปลายทาง บริการผ่อนชำระ
          บริการทดลองใช้สินค้าที่โชว์ก่อนตัดสินใจซื้อ
          บริการจัดส่งและติดตั้งสินค้าโดยทีมขนส่งจากฟาชิแคร์อีกทั้งยังมีการรับประกัน
          สินค้าทุกชิ้นหมดกังวลเรื่องการซ่อม หรือหาอะไหล่เมื่อสินค้ามีปัญหา
        </TypoGraphyTitle>
      </Grid>
      {/* <Box
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: "center",
          width: "100%",
          gap: "10%",
          my: "2%",
        }}
      >
        <Img src="/Lazada.png" width={"150px"} />
        <Img src="/Lazada2.png" width={"200px"} />
      </Box> */}
      {/* <Grid container justifyContent={'center'} sx={{display:{xs:"none",md:"flex"},margin:"12px 0"}}>
                <Grid xs={3} container justifyContent={"center"}>
                    <img src={shoppee} style={{ width: '200px', height: '200px', objectFit: "contain" }} />
                </Grid>
                <Grid xs={3} container justifyContent={"center"}>
                    <img src={lazada} style={{ width: '200px', height: '200px', objectFit: "contain" }} />
                </Grid>
            </Grid> */}
      <Grid className="flex justify-center w-full">
        <div>
          <hr className="md:w-[308px] h-1 my-12 bg-[#134C30] text-green-700 " />
        </div>
      </Grid>
    </Grid>
  );
}

export default AboutUs;
