// import * as React from 'react';
// import { Paper, Typography, TextField, Button, Grid, Box } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import logo from '../image/login/logo-2.png';
// import circle from '../image/login/Ellipse 24.png';
// import { useRef, useEffect, useState } from 'react';
// import axios from 'axios';
// import toast, { Toaster } from 'react-hot-toast';
// import Swal from 'sweetalert2'
// import { useNavigate } from 'react-router-dom';

// function Reset() {

//   const emailOrPhoneRef = useRef<any>(null);
//   const notify = (msg:any, options:any) => toast(msg, {...options});
//   const navigate = useNavigate();

//   const Resetpassword = async () => {

//     const emailOrPhone = emailOrPhoneRef.current.value;

//     if (emailOrPhone != null && emailOrPhone !== '' && emailOrPhone !== undefined) {

//       const sendData = {
//         url: `${process.env.REACT_APP_BASE_URL}`,
//         email: emailOrPhone,
//         phone: emailOrPhone
//       }

//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/reset/password`, sendData);

//       if (response.data.message === 'Please check your email to change your password') {
//           Swal.fire({
//             title: "Send reset password link to your email complete",
//             text: response.data.message,
//             icon: "success"
//         }).then(() => {
//           return navigate('/');
//         })
//       } else {
//         Swal.fire({
//           title: "Error Send reset password link to your email",
//           text: response.data.message,
//           icon: "error"
//       })
//       }

//     } else {
//         notify('Please input your email or phone numbers', {
//           duration: 5000,
//           style: {
//               color: "white",
//               backgroundColor: "red"
//           },
//           icon: '❌',
//           position: 'bottom-center',
//           iconTheme: {
//               primary: '#000',
//               secondary: '#fff',
//           },
//       });
//     }

//   }

//   return (
//     <Grid
//       container
//       sx={{
//         p: { xs: 1, md: 10 } ,
//         position: 'relative',
//         background: '#094a2a',
//         height: '100vh',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         overflow: { xs: 'auto', md: 'hidden' }
//       }}
//     >
//       <Toaster />
//       <Grid item md={6} textAlign={'center'} sx={{ p: { xs: 5, md: 5 } }}>
//         <img src={logo} alt="Logo" />
//       </Grid>
//       <Grid item md={6} textAlign={'left'} sx={{ p: { xs: 1, md: 5 } }}>
//         <Paper elevation={2} sx={{ p: { xs: 3, md: 8 }, borderRadius: 4 }}>
//           <Typography variant='h4' sx={{ color: '#094a2a', fontWeight: '600' }}>
//             Reset Password
//           </Typography>
//           <br></br>
//           <Typography variant='body1'>
//             Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Netus nisl, ipsum auctor
//           </Typography>
//           <br></br>
//           <Typography variant='body1' sx={{ color: '#949CA9' }}>
//             E-mail or phone number
//           </Typography>
//           <TextField
//             id="outlined-lastName-input"
//             type="text"
//             autoComplete="current-lastName"
//             variant="outlined"
//             fullWidth
//             sx={{
//               borderRadius: '4px',
//             }}
//             inputRef={emailOrPhoneRef}
//           />
//           <br></br>
//           <br></br>
//           <Button
//             variant="contained"
//             fullWidth
//             sx={{
//               mt: 2,
//               height: '50px',
//               borderRadius: '10px',
//               bgcolor: '#0A3A22',
//             }}
//             onClick={() => Resetpassword()}
//           >
//             Next
//           </Button>

//         </Paper>


//       </Grid>
//       <Box sx={{ position: 'absolute', left: '-10%', top: '-10%' }}>
//         <img src={circle} alt="" />
//       </Box>
//       <Box sx={{ position: 'absolute', right: '-10%', bottom: '-10%' }}>
//         <img src={circle} alt="" />
//       </Box>
//     </Grid>

//   );
// }

// export default Reset;


import * as React from 'react';
import { Paper, Typography, TextField, Button, Grid, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from '../image/login/logo-2.png';
import circle from '../image/login/Ellipse 24.png';
import { useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Reset() {
  const emailOrPhoneRef = useRef<any>(null);
  const notify = (msg:any, options:any) => toast(msg, {...options});
  const navigate = useNavigate();

  const Resetpassword = async () => {
    const emailOrPhone = emailOrPhoneRef.current.value;

    if (emailOrPhone != null && emailOrPhone !== '' && emailOrPhone !== undefined) {
      const sendData = {
        url: `${process.env.REACT_APP_BASE_URL}`,
        email: emailOrPhone,
        phone: emailOrPhone
      };

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/reset/password`, sendData);

        if (response.data.message === 'Please check your email to change your password') {
          Swal.fire({
            title: "Send reset password link to your email complete",
            text: response.data.message,
            icon: "success"
          }).then(() => {
            navigate('/');
          });
        } else {
          Swal.fire({
            title: "Error Send reset password link to your email",
            text: response.data.message,
            icon: "error"
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "An error occurred while sending the reset password link",
          icon: "error"
        });
      }
    } else {
      notify('Please input your email or phone numbers', {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red"
        },
        icon: '❌',
        position: 'bottom-center',
        iconTheme: {
          primary: '#000',
          secondary: '#fff'
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        p: { xs: 1, md: 10 },
        position: 'relative',
        background: '#094a2a',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: { xs: 'auto', md: 'hidden' },
        zIndex: 1 
      }}
    >
      <Toaster />
      <Grid item md={6} textAlign={'center'} sx={{ p: { xs: 5, md: 5 } }}>
        <img src={logo} alt="Logo" style={{ zIndex: 2 }} />
      </Grid>
      <Grid item md={6} textAlign={'left'} sx={{ p: { xs: 1, md: 5, lg:10 }, zIndex: 2 }}>
        <Paper elevation={2} sx={{ p: { xs: 3, md: 8 }, borderRadius: 4, position: 'relative', zIndex: 2 }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: '#094a2a', position: 'absolute', left: 16, zIndex: 3 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h4' sx={{
            color: '#094a2a',
            fontWeight: '600',
            ml: { xs: 5 ,md:0},
            zIndex: 2
          }}>
            Reset Password
          </Typography>
          <br />
          <Typography variant='body1'>
            Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Netus nisl, ipsum auctor
          </Typography>
          <br />
          <Typography variant='body1' sx={{ color: '#949CA9' }}>
            E-mail or phone number
          </Typography>
          <TextField
            id="outlined-lastName-input"
            type="text"
            autoComplete="current-lastName"
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '4px',
            }}
            inputRef={emailOrPhoneRef}
          />
          <br />
          <br />
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              height: '50px',
              borderRadius: '10px',
              bgcolor: '#0A3A22',
            }}
            onClick={Resetpassword}
          >
            Next
          </Button>
        </Paper>
      </Grid>
      <Box sx={{ position: 'absolute', left: '-10%', top: '-10%', zIndex: 0 }}>
        <img src={circle} alt="" />
      </Box>
      <Box sx={{ position: 'absolute', right: '-10%', bottom: '-10%', zIndex: 0 }}>
        <img src={circle} alt="" />
      </Box>
    </Grid>
  );
}

export default Reset;
