// import * as React from "react";
// import { Paper, Typography, TextField, Button, Grid,IconButton } from "@mui/material";
// import logo from "../image/login/logo-2.png";
// import circle from "../image/login/Ellipse 24.png";
// import toast, { Toaster } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import { useRef } from "react";
// import axios from "axios";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// function Register() {
//   const notify = (msg: any, options: any) => toast(msg, { ...options });
//   const navigate = useNavigate();

//   const firstnameRef = useRef<any>(null);
//   const lastnameRef = useRef<any>(null);
//   const emailRef = useRef<any>(null);
//   const phoneRef = useRef<any>(null);
//   const passwordRef = useRef<any>(null);
//   const confirmPasswordRef = useRef<any>(null);

//   const singUp = async () => {
//     const firstname = firstnameRef.current.value;
//     const lastname = lastnameRef.current.value;
//     const email = emailRef.current.value;
//     const phone = phoneRef.current.value;
//     const password = passwordRef.current.value;
//     const confirmPassword = confirmPasswordRef.current.value;

//     if (
//       firstname != null &&
//       firstname !== "" &&
//       firstname !== undefined &&
//       lastname != null &&
//       lastname !== "" &&
//       lastname !== undefined &&
//       email != null &&
//       email !== "" &&
//       email !== undefined &&
//       phone != null &&
//       phone !== "" &&
//       phone !== undefined &&
//       password != null &&
//       password !== "" &&
//       password !== undefined &&
//       confirmPassword != null &&
//       confirmPassword !== "" &&
//       confirmPassword !== undefined
//     ) {
//       const sendData = {
//         firstname: firstname,
//         lastname: lastname,
//         email: email,
//         phoneNumber: phone,
//         password: password,
//         passwordConfirm: confirmPassword,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/members/register/`,
//         sendData
//       );

//       if (response.data.message === "Already registered") {
//         notify(response.data.message, {
//           duration: 5000,
//           style: {
//             color: "white",
//             backgroundColor: "green",
//           },
//           icon: "✔",
//           position: "bottom-center",
//           iconTheme: {
//             primary: "#000",
//             secondary: "#fff",
//           },
//         });

//         setTimeout(() => {
//           return navigate("/");
//         }, 5000);
//       } else {
//         notify(response.data.message, {
//           duration: 5000,
//           style: {
//             color: "white",
//             backgroundColor: "red",
//           },
//           icon: "❌",
//           position: "bottom-center",
//           iconTheme: {
//             primary: "#000",
//             secondary: "#fff",
//           },
//         });
//       }
//     } else {
//       notify("Please fill in complete information.", {
//         duration: 5000,
//         style: {
//           color: "white",
//           backgroundColor: "red",
//         },
//         icon: "❌",
//         position: "bottom-center",
//         iconTheme: {
//           primary: "#000",
//           secondary: "#fff",
//         },
//       });
//     }
//   };

//   return (
//     <Grid
//       container
//       sx={{
//         flexGrow: 1,
//         backgroundColor: "#084a2a",
//         display: "flex",
//         width: "100%",
//         height:"100vh",
//         justifyContent: "center",
//         alignItems: "center",
//         // paddingY: "3%",
//       }}
//     >
//       <Toaster />
//       <Grid
//         container
//         display="flex"
//         justifyContent="space-evenly"
//         alignItems="center"
//       >
//         <Grid className="py-4">
//           <img
//             src={logo}
//             alt="Logo"
//             style={{ width: "342px", height: "101px" }}
//           />
//         </Grid>
//         <Grid sx={{ width: { md: "40%", xs: "100%" } }}>
//           <Paper
//             sx={{
//               padding: "5%",
//               borderRadius: "20px",
//               backgroundColor: "#FFFFFF",
//               boxShadow: "0px 19px 40px 0px #000000D",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Grid item sx={{ width: "100%" }}>

//             <IconButton onClick={() => navigate(-1)} sx={{ color: '#094a2a', position: 'absolute', left: 16, zIndex:3 }}>
//             <ArrowBackIcon />
//             </IconButton>

//               <Typography
//                 className="flex justify-center "
//                 sx={{
//                   fontSize: "30px",
//                   fontWeight: 400,
//                   lineHeight: "24px",
//                   letterSpacing: "0em",
//                   mt: "2",
//                   width: "100%",
//                 }}
//               >
//                 สมัครสมาชิก <br />
//               </Typography>
//               <Typography
//                 sx={{
//                   fontSize: "16px",
//                   lineHeight: "24px",
//                   fontWeight: 300,
//                 }}
//               >
//                 First Name
//               </Typography>
//               <TextField
//                 id="outlined-firstName-input"
//                 type="text"
//                 autoComplete="current-firstName"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={firstnameRef}
//               />
//             </Grid>

//             <Grid item sx={{ mt: 2, width: "100%" }}>
//               <Typography
//                 sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300,zIndex:2 }}
//               >
//                 Last Name
//               </Typography>
//               <TextField
//                 id="outlined-lastName-input"
//                 type="text"
//                 autoComplete="current-lastName"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={lastnameRef}
//               />
//             </Grid>

//             <Grid item sx={{ mt: 2, width: "100%" }}>
//               <Typography
//                 sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
//               >
//                 E-mail
//               </Typography>
//               <TextField
//                 id="outlined-email-input"
//                 type="email"
//                 autoComplete="current-email"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={emailRef}
//               />
//             </Grid>

//             <Grid item sx={{ mt: 2, width: "100%" }}>
//               <Typography
//                 sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
//               >
//                 Phone
//               </Typography>
//               <TextField
//                 id="outlined-Phone-input"
//                 type="tel"
//                 autoComplete="current-Phone"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={phoneRef}
//               />
//             </Grid>

//             <Grid item sx={{ mt: 2, width: "100%" }}>
//               <Typography
//                 sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
//               >
//                 Password
//               </Typography>
//               <TextField
//                 id="outlined-Password-input"
//                 type="password"
//                 autoComplete="current-Password"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={passwordRef}
//               />
//             </Grid>

//             <Grid item sx={{ mt: 2, width: "100%" }}>
//               <Typography
//                 sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
//               >
//                 Confirm Password
//               </Typography>
//               <TextField
//                 id="outlined-ConfirmPassword-input"
//                 type="password"
//                 autoComplete="current-ConfirmPassword"
//                 variant="outlined"
//                 sx={{
//                   borderRadius: "4px",
//                   width: "100%",
//                   zIndex:2
//                 }}
//                 inputRef={confirmPasswordRef}
//               />
//             </Grid>

//             <Grid item sx={{ mb: 2 }}>
//               <Button
//                 variant="contained"
//                 sx={{
//                   mt: 2,
//                   width: "156px",
//                   height: "46px",
//                   borderRadius: "23px",
//                   bgcolor: "#0A3A22",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   lineHeight: "24px",
//                   zIndex:2
//                 }}
//                 onClick={() => {
//                   singUp();
//                 }}
//               >
//                 Create User
//               </Button>
//             </Grid>
//           </Paper>
//         </Grid>
//       </Grid>

//       <Grid
//         position={"absolute"}
//         display="flex"
//         width={"100%"}
//         justifyContent={"end"}
//       >
//         <img src={circle} alt="" style={{ width: "700px", height: "700px" }} />
//       </Grid>
//     </Grid>
//   );
// }

// export default Register;


import * as React from "react";
import { Paper, Typography, TextField, Button, Grid, IconButton } from "@mui/material";
import logo from "../image/login/logo-2.png";
import circle from "../image/login/Ellipse 24.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Register() {
  const notify = (msg: any, options: any) => toast(msg, { ...options });
  const navigate = useNavigate();

  const firstnameRef = useRef<any>(null);
  const lastnameRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const confirmPasswordRef = useRef<any>(null);

  const singUp = async () => {
    const firstname = firstnameRef.current.value;
    const lastname = lastnameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (
      firstname != null &&
      firstname !== "" &&
      firstname !== undefined &&
      lastname != null &&
      lastname !== "" &&
      lastname !== undefined &&
      email != null &&
      email !== "" &&
      email !== undefined &&
      phone != null &&
      phone !== "" &&
      phone !== undefined &&
      password != null &&
      password !== "" &&
      password !== undefined &&
      confirmPassword != null &&
      confirmPassword !== "" &&
      confirmPassword !== undefined
    ) {
      const sendData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phoneNumber: phone,
        password: password,
        passwordConfirm: confirmPassword,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/members/register/`,
        sendData
      );

      if (response.data.message === "Already registered") {
        notify(response.data.message, {
          duration: 5000,
          style: {
            color: "white",
            backgroundColor: "green",
          },
          icon: "✔",
          position: "bottom-center",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });

        setTimeout(() => {
          return navigate("/");
        }, 5000);
      } else {
        notify(response.data.message, {
          duration: 5000,
          style: {
            color: "white",
            backgroundColor: "red",
          },
          icon: "❌",
          position: "bottom-center",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
      }
    } else {
      notify("Please fill in complete information.", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        backgroundColor: "#084a2a",
        display: "flex",
        width: "100%",
        height: "105vh",
        justifyContent: "center",
        alignItems: "center",
        
      }}
    >
      <Toaster />
      <Grid
        container
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid className="py-4">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "342px", height: "101px" }}
          />
        </Grid>
        <Grid sx={{ width: { md: "40%", xs: "100%" } }}>
          <Paper
            sx={{
              padding: "5%",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 19px 40px 0px #000000D",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: 'relative' // Added this line
            }}
          >
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                color: '#094a2a',
                position: 'absolute',
                top: 16,
                left: 16,
                zIndex: 3
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <Grid item sx={{ width: "100%" }}>
              <Typography
                className="flex justify-center "
                sx={{
                  fontSize: "30px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  mt: "2",
                  marginBottom: 5,
                  width: "100%",
                }}
              >
                สมัครสมาชิก <br />
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 300,
                }}
              >
                First Name
              </Typography>
              <TextField
                id="outlined-firstName-input"
                type="text"
                autoComplete="current-firstName"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={firstnameRef}
              />
            </Grid>

            <Grid item sx={{ mt: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300, zIndex: 2 }}
              >
                Last Name
              </Typography>
              <TextField
                id="outlined-lastName-input"
                type="text"
                autoComplete="current-lastName"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={lastnameRef}
              />
            </Grid>

            <Grid item sx={{ mt: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
              >
                E-mail
              </Typography>
              <TextField
                id="outlined-email-input"
                type="email"
                autoComplete="current-email"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={emailRef}
              />
            </Grid>

            <Grid item sx={{ mt: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
              >
                Phone
              </Typography>
              <TextField
                id="outlined-Phone-input"
                type="tel"
                autoComplete="current-Phone"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={phoneRef}
              />
            </Grid>

            <Grid item sx={{ mt: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
              >
                Password
              </Typography>
              <TextField
                id="outlined-Password-input"
                type="password"
                autoComplete="current-Password"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={passwordRef}
              />
            </Grid>

            <Grid item sx={{ mt: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 300 }}
              >
                Confirm Password
              </Typography>
              <TextField
                id="outlined-ConfirmPassword-input"
                type="password"
                autoComplete="current-ConfirmPassword"
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  zIndex: 2
                }}
                inputRef={confirmPasswordRef}
              />
            </Grid>

            <Grid className=" text-red-600" item sx={{ mt: 2, width: "100%" }}>
              <Typography>สมัครสมาชิกรับส่วนลดทันที 5%</Typography>
              
              </Grid>

            <Grid item sx={{ mb: 2 }}>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "46px",
                  borderRadius: "23px",
                  bgcolor: "#0A3A22",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  zIndex: 2,
                }}
                onClick={() => {
                  singUp();
                }}
              >
                Create User
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        position={"absolute"}
        display="flex"
        width={"100%"}
        justifyContent={"end"}
      >
        <img src={circle} alt="" style={{ width: "700px", height: "700px" }} />
      </Grid>
    </Grid>
  );
}

export default Register;
