import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Container,
} from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import React, { useContext } from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import MaskImg from "../assets/images/mask.png";
import ButtonCal from "../component/ButtonCal";
import { Product } from "../component/Product";
import { useRef, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Checkbox,
  FormControl,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import mask2 from "../image/maskwhite.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { ShoppingBagOutlined } from "@mui/icons-material";
import TagDisconthome from "../component/TagDisconthome";

const cookies = new Cookies(null, { path: "/" });
const steps = ["Shopping Cart", "Checkout", "Order Complete"];
const prod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#0A3A22",
    borderTopWidth: 7,
    borderRadius: 1,
  },
}));

const CardCustomProd: any = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  fontSize: "24px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "220px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "240px",
  },
}));

const CardCustomProdpro = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  fontSize: "22px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "90%",
  },
}));

export default function Cart() {
  const navigate = useNavigate();
  const context: any = useOutletContext();

  const [carts, setCarts] = useState([]);
  const [totals, setTotals] = useState(0);
  const [checked, setChecked] = useState<any>(false);
  const [dataWithChecked, setDataWithChecked] = useState<any>([]);

  const [totalItem, setTotalItem] = useState(0);

  const reloadCarts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/carts/get`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        const sortCartsByProductCreated = response.data.carts.sort(function (
          a: any,
          b: any
        ) {
          var d1: any = new Date(a.productCreatedAt);
          var d2: any = new Date(b.productCreatedAt);
          return d1 - d2;
        });
        const sortCartsByPriceCreated = sortCartsByProductCreated.sort(
          function (a: any, b: any) {
            var d1: any = new Date(a.priceCreatedAt);
            var d2: any = new Date(b.priceCreatedAt);
            return d1 - d2;
          }
        );

        setCarts(sortCartsByPriceCreated);
        setTotals(response.data.totals);
      });
  };

  
  const deteleCart = (priceId: any) => {
    Swal.fire({
      title: "ยืนยันการลบสินค้า ?",
      text: "แน่ใจที่จะลบสินค้าใช่หรือไม่ หากใช่สินค้านี้จะถูกลบออกไปจากตะกร้า",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
    }).then((status) => {
      if (status.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/carts/delete`,
            {
              price_id: priceId,
            },
            {
              headers: {
                token: cookies.get("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.message === undefined) {
              Swal.fire({
                title: "ลบสินค้าในตะกร้าเรียบร้อยแล้ว",
                icon: "success",
              }).then(() => {
                // window.location.reload();
                reloadCarts();
              });
            } else {
              Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: `ลบสินค้าในตะกร้าไม่สำเร็จ (${response.data.message})`,
                icon: "error",
              });
            }
          });
      }
    });
  };

  const handleTotalItem = () => {
    let total = 0
    total = carts.reduce((acc, item)=>{
      acc += item['quantity'] * (item['is_size'] !== 'price_by_size' ? item['price_per_each'] : item[item['size']] || item['price_per_each'])
      return acc
    },0)

    setTotalItem(total)
  }

  useEffect(() => {
    if (
      cookies.get("token") != null &&
      cookies.get("token") !== "" &&
      cookies.get("token") !== undefined
    ) {
      reloadCarts();
    } else {
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    console.log("Totals updated:", totals);
  }, [totals]);

  useEffect(() => {
    handleTotalItem()
  }, [carts])

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkAndSetTotals = async (data: any) => {
    let total = 0;

    await Promise.all(
      data.map((element: any) => {
        const price_per_each = element.price_per_each;
        const count_quantity = element.quantity;

        total += count_quantity * price_per_each;
      })
    );

    setTotals(total);
  };

  const handleChange = async (event: any, cart: any) => {
    let temp: any = dataWithChecked;
    let total = 0;

    if (event.target.checked) {
      total = cart.quantity * cart.price_per_each + totals;
      temp.push(cart);
    } else {
      total = totals - cart.quantity * cart.price_per_each;
      temp = await temp.filter(
        (obj: any) => obj.product_id !== cart.product_id
      );
    }

    setDataWithChecked(temp);
    setTotals(total);

    checkAndSetTotals(temp);
  };

  const subTotalData = (quantity: number, cart: any) => {
    let tempData: any = [...carts];

    const findIndex = tempData.findIndex((element: any) => {
      return (
        cart.product_id === element.product_id &&
        cart.product_price_id === element.product_price_id
      );
    });

    tempData[findIndex] = {
      ...tempData[findIndex],
      quantity: quantity,
    };

    setCarts(tempData);

    let total: number = tempData.reduce((acc: number, current: any) => {
      return acc + current.quantity * current.price_per_each;
    }, 0);

    setTotals(total);
  };

  const handleClick = () => {
    localStorage.setItem("data", JSON.stringify({ dataWithChecked, totals:totalItem }));
    navigate("/cart_verify");
  };

  return (
    <Box
      sx={{
        paddingBottom: { md: 0, xs: 10 },
        paddingX: { md: "5%", xs: 0 },
        paddingTop: { md: 5, xs: 0 },
      }}
    >
      <Card
        elevation={2}
        sx={{
          padding: { md: 2, xs: 0 },
          borderRadius: 10,
          width: "100%",
        }}
      >
        <CardContent>
          <Grid container columnSpacing={4} justifyContent={"center"}>
            <Grid item md={12}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>

          <br></br>
          <TableContainer
            component={Paper}
            elevation={2}
            sx={{ borderRadius: 6 }}
          >
            <Table
              sx={{ minWidth: { xs: "auto", md: 650 } }}
              aria-label="simple table"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                    align="center"
                  >
                    Price
                  </TableCell>

                  <TableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                    align="center"
                  >
                    Size
                  </TableCell>

                  <TableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                    align="center"
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                    align="right"
                  >
                    Subtotal
                  </TableCell>
                  <TableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carts.map((cart: any, index) => {

                  
                  const per_price = (cart.is_size == 'single_price' || cart.is_size == 'single_price_size') ? cart.price_per_each : cart[cart.size] || cart.price_per_each

                  const totalOneProduct = cart.quantity * per_price
                  
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <FormControlLabel
                          sx={{ color: "secondary.light" }}
                          value={""}
                          control={
                            <Checkbox
                              onChange={(event) => {
                                handleChange(event, cart);
                              }}
                            />
                          }
                          label={""}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <img
                          src={
                            cart.product_image != null
                              ? cart.product_image.replace(
                                  `{api_path}`,
                                  process.env.REACT_APP_API_URL
                                )
                              : `${process.env.REACT_APP_BASE_URL}/404-not-found.png`
                          }
                          style={{ maxWidth: 70 }}
                          alt=""
                        ></img>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          columnSpan: { xs: 5 },
                          p: 0,
                        }}
                      >
                        <ListItem>
                          <ListItemText
                            primary={cart.product_name}
                            secondary={
                              <Box>
                                {cart.product_price_name}
                                <Box
                                  sx={{
                                    display: { xs: "table-cell", md: "none" },
                                  }}
                                >
                                  <ButtonCal
                                    getSubTotal={subTotalData}
                                    size="small"
                                    data={cart}
                                  ></ButtonCal>
                                </Box>
                              </Box>
                            }
                          />
                        </ListItem>
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="center"
                      >
                        {per_price}
                      </TableCell>

                      <TableCell
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="center"
                      >
                        {cart.size?.toUpperCase() || ""}
                      </TableCell>

                      <TableCell
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="center"
                      >
                        <ButtonCal
                          getSubTotal={subTotalData}
                          size="small"
                          data={cart}
                        ></ButtonCal>
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="right"
                      >
                        {totalOneProduct}
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                        align="right"
                      >
                        <Button
                          onClick={() => {
                            deteleCart(cart.product_price_id);
                          }}
                          variant="contained"
                        >
                          X
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Divider></Divider>

                    <Box textAlign={"right"} p={3}>
                      <Typography textAlign={"left"} variant="h6">
                        กรุณาตรวจสอบรายการสินค้าที่ท่านเลือกหากถูกต้องครบถ้วนแล้วกดยืนยัน
                      </Typography>
                      <div className="relative lg:bottom-10 bottom-0">
                        <Typography variant="h6">ยอดรวมทั้งหมด</Typography>
                        <Typography variant="h6">
                          
                          {/* {totals} */}
                          {totalItem}
                          </Typography>
                        {/* <Button onClick={handleClick} variant="contained">สั่งซื้อ</Button> */}

                        <Button
                          onClick={handleClick}
                          variant="contained"
                          disabled={carts.length === 0}
                        >
                          สั่งซื้อ
                        </Button>
                      </div>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <br></br>
      <Typography variant="h4" className="pt-10 md:px-0 px-[1%]">
        สินค้าที่เกี่ยวข้อง
      </Typography>
      {/* <Grid container spacing={3}>
                {
                    [1, 2, 3, 4].map((item, index) => {
                        return (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                                <Product></Product>
                            </Grid>
                        )

                    })
                }
            </Grid> */}

      <Grid container className="gap-11 py-10">
        <Grid
          container
          gap="16px"
          maxWidth="1650px"
          sx={{ margin: "0 auto", paddingX: { md: 0, xs: "1%" } }}
        >
          <Swiper
            modules={[Autoplay]}
            // loop={true}
            // autoplay={{
            //   delay: 3,
            //   pauseOnMouseEnter: true,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {context[0].allProducts &&
              context[0].allProducts.map((item: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <CardCustomProdpro>
                      <div className="px-5">
                        <Box sx={{ position: "relative" }}>
                          <img
                            src={
                              item.image?.replace(
                                "{api_path}",
                                process.env.REACT_APP_API_URL
                              ) || "/404-not-found.png"
                            }
                            className="w-full h-[150px] md:h-[270px] object-contain"
                            alt={item.name}
                          />
                          <TagDisconthome discont={`20`} />
                        </Box>
                        <div className="text-center">
                          <div className="text-left mt-2">
                            <p className="text-xs font-light md:text-lg text-left h-12">
                              <b>{item.name}</b>
                            </p>
                            {/* <p className="text-[50%] md:text-[70%] font-light">
                              <b>
                                {item.description
                                  ?.split(" ")
                                  .slice(0, 5)
                                  .join(" ")}
                                {item.description?.split(" ").length > 5 &&
                                  " ..."}
                              </b>
                            </p> */}
                          </div>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className="pb-4 pt-2"
                          >
                            <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                              ฿ {item.price}
                            </p>
                            <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                              ขายแล้ว (1 ชิ้น)
                            </span>
                          </Grid>
                          <Button
                            className="items-center"
                            sx={{ boxShadow: 2, borderRadius: 5 }}
                            onClick={() => {
                              navigate(`/product_detail/${item.id}`);
                              window.scrollTo({ top: 0 });
                            }}
                          >
                            <div className="px-2 items-center flex text-md">
                              <ShoppingBagOutlined
                                sx={{
                                  fontSize: { xs: "14px", md: "20px" },
                                  mx: "2px",
                                }}
                              />
                              หยิบใส่ตะกร้า
                            </div>
                          </Button>
                        </div>
                      </div>
                    </CardCustomProdpro>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
}
