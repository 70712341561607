import AdminPage from "./AdminPage";
import AdminLogin from "./AdminLogin";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";

const Admin = () => {
    const context: any = useOutletContext();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const data = context[0]
        if(!data.isLogged || !data.isAdmin)
        {
            setLoaded(true);
            return;
        }
        
        data.isAdmin && setIsAdmin(true);
        setLoaded(true);
    }, [context])

    return (
        <>
            {
                loaded ? isAdmin ? <AdminPage /> : <AdminLogin /> : <></>
            }
        </>
    )
}

export default Admin;