import {  Box, Button, Card, CardContent, Grid, Stepper, Typography } from "@mui/material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React from "react";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import CorrectImg from '../assets/images/correct.png';
import { Link } from "react-router-dom";
const steps = ['Shopping Cart', 'Checkout', 'Order Complete'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0A3A22',
        borderTopWidth: 7,
        borderRadius: 1,
    },
}));


function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3)
];


export default function CartComplete() {

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box p={5}>
            <Card
                elevation={2}
                sx={{
                    padding: 2,
                    borderRadius: 10
                }}>
                <CardContent >
                    <Grid container columnSpacing={4} justifyContent={'center'}>
                        <Grid item md={12} >

                            <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>

                    </Grid>
                    <br></br> <br></br>
                    <Box   textAlign={'center'} lineHeight={4}>
                        <Grid justifyContent={'center'} className="flex justify-center h-full w-full">
                            <div className="flex flex-col items-center">
                        <img className="flex justify-center" src={CorrectImg}></img>
                        <Typography variant="h4" className="pt-7">
                            สั่งซื้อสำเร็จ
                        </Typography>
                        <Typography variant="h5">
                            ขอบคุณสำหรับการสั่งซื้อ
                        </Typography>
                        <Link to={'/'}>
                            <Button variant="contained">กลับไปหน้า SHOP</Button>
                        </Link>
                        </div>
                        </Grid>
                    </Box>

                </CardContent>


            </Card>

        </Box >
    )
}

