import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  Description,
  Details,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { unescape } from "querystring";
import { Interface } from "readline";

interface InputSize {
  s?: number | boolean;
  m?: number | boolean;
  l?: number | boolean;
  xl?: number | boolean;
}

const initialSize: InputSize = {
  s: 0,
  m: 0,
  l: 0,
  xl: 0,
};

const initialSizeError: InputSize = {
  s: false,
  m: false,
  l: false,
  xl: false,
};

const EditProduct = ({ param_id }: { param_id: string }) => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const notify = (msg: any, options: any) => toast(msg, { ...options });
  const [show, setShow] = useState(false);

  const [categoriesRef, setCategoriesRef] = useState<any>([]);
  const [categoriesSubRef, setCategoriesSubRef] = useState<any>([]);

  const [productsRef, setProductsRef] = useState<any>([]);
  const productImageRef = useRef<any>("");
  const descriptionRefValue = useRef<any>("");
  const [productIdRef, setProductsIdRef] = useState<any>("");
  const [productNameRef, setProductNameRef] = useState<any>("");
  const [productPriceRef, setProductPriceRef] = useState<any>("");
  const [productStockRef, setProductStockRef] = useState<any>("");
  const [descriptionRef, setDescriptionRef] = useState<any>("");
  const [categoryRef, setCategoryRef] = useState<any>("");
  const [categorySubRef, setCategorySubRef] = useState<any>("");

  const [loading, setLoading] = useState<boolean>(false);

  // ราคาเดียว มีไซต์ และ ตามไซต์
  const [togglePrice, setTogglePrice] = useState<string>("ราคาเดียว");
  const [cold, setCold] = useState<boolean>(false);

  const [inputSizes, setInputSizes] = useState<InputSize>(initialSize);

  const [fileImg, setFileImg] = useState<any>(undefined);

  const [sizeError, setSizeError] = useState<InputSize>(initialSizeError);

  // console.log(inputSizes)
  const handleUpload = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }

    setFileImg(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };

  const editorRef = useRef<any>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    if (
      cookies.get("token") == null ||
      cookies.get("token") === "" ||
      cookies.get("token") === undefined
    ) {
      return navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/token/check`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        if (response.data.isAdmin !== 1) {
          return navigate("/");
        }
      });
    setEditorLoaded(true);
    handleResize(); // Call handleResize on component mount to set initial size
    getCategories();
    getSubCategories();
    getProducts();

    window.addEventListener("resize", handleResize); // Add event listener for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener on component unmount
      setEditorLoaded(false);
    };
  }, []);

  const handleResize = () => {
    if (editorRef.current) {
      // Get the parent container's width
      const parentWidth = editorRef.current.parentElement.clientWidth;
      // Set the editor's width to be 90% of the parent container's width
      editorRef.current.style.width = `${parentWidth * 0.9}px`;
    }
  };

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        const data = response.data;

        setCategoriesRef(data);
      });
  };

  const getSubCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/getSub`)
      .then((response) => {
        const data = response.data.data;

        setCategoriesSubRef(data);
      });
  };

  const getProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/id/${param_id}`)
      .then((response) => {
        const data = response.data;

        if (cold === false) {
          setCold(true);
          setProductsIdRef(data["product"][0]?.["code"]);
          setProductNameRef(data["product"][0]?.["name"]);
          setProductPriceRef(data["priceOfProduct"][0]?.["price"]);
          setProductStockRef(data["priceOfProduct"][0]?.["stock"]);
          setDescriptionRef(data["product"][0]?.["description"]);
          setCategoryRef(data["product"][0]?.["category_id"]);
          setCategorySubRef(data["product"][0]?.["subcategory_id"]);
          setInputSizes({
            s: data["priceOfProduct"][0]?.["s"] || 0,
            m: data["priceOfProduct"][0]?.["m"] || 0,
            l: data["priceOfProduct"][0]?.["l"] || 0,
            xl: data["priceOfProduct"][0]?.["xl"] || 0,
          });

          // is_size single_price คือ ราคาเดียว และ price_by_size ราคาตามไซต์ และ single_price_size ราคาเดียว มีไซต์
          const isSize = data["priceOfProduct"][0]?.["is_size"];
          const isSizeName =
            isSize == "single_price"
              ? "ราคาเดียว"
              : isSize == "price_by_size"
              ? "ตามไซต์"
              : "มีไซต์";

          setTogglePrice(isSizeName);
        }

        setProductsRef(data);
      });
  };

  const handleChangePriceSize = (event: any) => {
    setInputSizes({ ...inputSizes, [event.target.name]: +event.target.value });
    setSizeError(initialSizeError);
  };
  const handleChange = (event: any) => {
    setCategoryRef(event.target.value);
  };

  const handleChangeSub = (event: any) => {
    setCategorySubRef(event.target.value);
  };


  const handleError = () => {
    const newSizeError = { ...sizeError };
    const sizes: (keyof InputSize)[] = ['s', 'm', 'l', 'xl'];
  
    sizes.forEach(size => {
      if (inputSizes[size] === 0) {
        newSizeError[size] = true;
      }
    });
  
    setSizeError(newSizeError);
  
    return Object.values(newSizeError).some(val => val);
  };


  
  
  const editProduct = async () => {

    let error: boolean = false;
    let sizeData = {};
    
    const SINGLE = 'single_price';
    const SINGLE_PRICE_SIZE = 'single_price_size';
    const BYSIZE = 'price_by_size';
    
    if (togglePrice === "ราคาเดียว") {
      sizeData = { is_size: SINGLE };
    } else if (togglePrice === "มีไซต์") {
      sizeData = { is_size: SINGLE_PRICE_SIZE };
    } else if (togglePrice === "ตามไซต์") {
      error = handleError();
      sizeData = {
        s: inputSizes.s,
        m: inputSizes.m,
        l: inputSizes.l,
        xl: inputSizes.xl,
        is_size: BYSIZE,
      };
    }
    
    if (error) {
      console.log("first error");
      return;
    }
    
  
    console.log(sizeData)

    console.log("first");

    const productId = productIdRef;
    // const productDetailsName = productDetailsNameRef;
    const productName = productNameRef;
    const productPrice = productPriceRef;
    const productStock = productStockRef;
    const description = descriptionRefValue.current.editor
      .getData()
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "");
    Swal.fire({
      title: "ยืนยันการแก้ไขสินค้านี้ ?",
      text: "แน่ใจที่จะแก้ไขสินค้านี้ใช่หรือไม่ หากใช่สินค้านี้จะถูกแก้ไข",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, แก้ไขเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
    }).then(async (status) => {
      if (status.isConfirmed) {
        if (
          productId != null &&
          productId !== "" &&
          productId !== undefined &&
          productName != null &&
          productName !== "" &&
          productName !== undefined &&
          productPrice != null &&
          productPrice !== "" &&
          productPrice !== undefined &&
          productStock != null &&
          productStock !== "" &&
          productStock !== undefined &&
          categoryRef != null &&
          categoryRef !== "" &&
          categoryRef !== undefined
        ) {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/products/editProduct`,
              {
                id: param_id,
                code: productId,
                name: productName,
                description: description,
                category_id: categoryRef,
                categorySub_id: categorySubRef,
                price: productPrice,
                stock: productStock,
                size_data:sizeData
              },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            )
            .then(async (response) => {
              console.log(response.data);
              if (response.data.message === "OK") {
                Swal.fire({
                  title: "แก้ไขสินค้าเรียบร้อยแล้ว",
                  icon: "success",
                });
                if (fileImg !== undefined) {
                  const formData = new FormData();
                  const product_id = response.data.id;
                  formData.append("file", fileImg);

                  await axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/upload/productImg`,
                      formData,
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    )
                    .then(async (response) => {
                      // console.log(response);

                      if (response.data.message == "OK") {
                        await axios.post(
                          `${process.env.REACT_APP_API_URL}/products/removeImage`,
                          {
                            product_id: param_id,
                          },
                          {
                            headers: {
                              token: cookies.get("token"),
                            },
                          }
                        );

                        await axios.post(
                          `${process.env.REACT_APP_API_URL}/products/insertpathimg`,
                          {
                            product_id: param_id,
                            path_img: response.data.path,
                          },
                          {
                            headers: {
                              token: cookies.get("token"),
                            },
                          }
                        );
                      }
                    });
                }
                const timer = setTimeout(() => {
                  setShow(true);
                  window.location.reload();
                }, 3000);

                return () => clearTimeout(timer);
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  text: `แก้ไขสินค้าไม่สำเร็จ (${response.data.message})`,
                  icon: "error",
                });
              }
            });
        } else {
          Swal.fire({
            title: "กรอกข้อมูลไม่ครบ",
            icon: "error",
          });
        }
      }
    });
  };

  const deleteProduct = async () => {
    Swal.fire({
      title: "ยืนยันการลบสินค้านี้ ?",
      text: "แน่ใจที่จะลบสินค้านี้ใช่หรือไม่ หากใช่สินค้านี้จะถูกลบ",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
    }).then(async (status) => {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/products/deleteProduct`,
          {
            product_id: param_id,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.message === "OK") {
            Swal.fire({
              title: "ลบสินค้าเรียบร้อยแล้ว",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `ลบสินค้าไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload}
        />
        <label htmlFor="upload-photo">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
      </Stack>
      <br></br>

      <Grid container spacing={2}>
        <Grid item sm={4} md={4}>
          <FormControl fullWidth>
            <FormLabel>เลือกหมวดหมู่</FormLabel>
            <Select
              id="standard-bare"
              variant="outlined"
              value={categoryRef}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
                height: "45px",
              }}
              onChange={handleChange}
            >
              {categoriesRef.map((item: any) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4} md={4}>
          <FormControl fullWidth>
            <FormLabel>เลือกหมวดหมู่ย่อย</FormLabel>
            <Select
              id="standard-bare"
              variant="outlined"
              value={categorySubRef}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
                height: "45px",
              }}
              onChange={handleChangeSub}
            >
              {categoriesSubRef
                .filter((f: any) => f.category_id === categoryRef)
                .map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4} md={4}>
          <FormControl fullWidth>
            <FormLabel>รหัสสินค้า</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="รหัสสินค้า"
              value={productIdRef}
              onChange={(e) => setProductsIdRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <FormControl fullWidth>
            <FormLabel>ชื่อสินค้า</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อสินค้า"
              value={productNameRef}
              onChange={(e) => setProductNameRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>จำนวน Stock</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="สินค้าในคลัง"
              value={productStockRef}
              onChange={(e) => setProductStockRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          {productsRef?.priceOfProduct && (
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">
                ขายตามไซต์ หรือ ราคาเดียว
              </FormLabel>
              <FormLabel id="demo-radio-buttons-group-label">
                * หากไม่มี Size ให้เลือก ราคาเดียว
              </FormLabel>
              <FormLabel id="demo-radio-buttons-group-label">
                * หากมี Size และ ราคาเดียว ให้เลือก ราคาเดียวและมีไซต์
              </FormLabel>
              <FormLabel id="demo-radio-buttons-group-label">
                * หากมี Size และ หลายราคา ให้เลือก ราคาตามไซต์
              </FormLabel>
              {togglePrice == "ตามไซต์" && (
                <FormLabel id="demo-radio-buttons-group-label">
                  * สามารถใส่ราคาซ้ำกันได้ และต้องใส่ให้ครบทุกช่อง
                </FormLabel>
              )}

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={
                  productsRef.priceOfProduct[0].is_size == "single_price"
                    ? "ราคาเดียว"
                    : productsRef?.priceOfProduct[0].is_size ==
                      "single_price_size"
                    ? "มีไซต์"
                    : "ตามไซต์"
                }
                name="radio-buttons-group"
                onChange={(e) => setTogglePrice(e.target.value)}
              >
                <Box display={"flex"}>
                  <FormControlLabel
                    value="ราคาเดียว"
                    control={<Radio />}
                    label="ราคาเดียว"
                  />
                  <FormControlLabel
                    value="มีไซต์"
                    control={<Radio />}
                    label="ราคาเดียวและมีไซต์"
                  />
                  <FormControlLabel
                    value="ตามไซต์"
                    control={<Radio />}
                    label="ราคาตามไซต์"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        {(togglePrice === "ราคาเดียว" || togglePrice === "มีไซต์") && (
          <Grid item sm={6} md={6}>
            <FormControl fullWidth>
              <FormLabel>ราคา</FormLabel>
              <TextField
                id="standard-bare"
                variant="outlined"
                placeholder="ราคา"
                value={productPriceRef}
                onChange={(e) => setProductPriceRef(e.target.value)}
                InputProps={{
                  sx: {
                    height: "45px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    py: "8px",
                    fontSize: "14px",
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}

        {/* S M L XL */}
        {togglePrice === "ตามไซต์" && (
          <Grid item sm={3} md={3}>
            <FormControl fullWidth>
              <FormLabel sx={{ color: sizeError.s ? "red" : "" }}>
                size S
              </FormLabel>
              <TextField
                id="standard-bare"
                variant="outlined"
                placeholder="ราคา"
                value={inputSizes.s}
                name="s"
                onChange={handleChangePriceSize}
                InputProps={{
                  sx: {
                    height: "45px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    py: "8px",
                    fontSize: "14px",
                  },
                }}
              />
            </FormControl>
            {sizeError.s && (
              <FormLabel sx={{ color: "red" }}>* required</FormLabel>
            )}
          </Grid>
        )}
        {togglePrice === "ตามไซต์" && (
          <Grid item sm={3} md={3}>
            <FormControl fullWidth>
              <FormLabel sx={{ color: sizeError.m ? "red" : "" }}>
                size M
              </FormLabel>
              <TextField
                id="standard-bare"
                variant="outlined"
                placeholder="ราคา"
                value={inputSizes.m}
                name="m"
                onChange={handleChangePriceSize}
                InputProps={{
                  sx: {
                    height: "45px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    py: "8px",
                    fontSize: "14px",
                  },
                }}
              />
            </FormControl>
            {sizeError.m && (
              <FormLabel sx={{ color: "red" }}>* required</FormLabel>
            )}
          </Grid>
        )}
        {togglePrice === "ตามไซต์" && (
          <Grid item sm={3} md={3}>
            <FormControl fullWidth>
              <FormLabel sx={{ color: sizeError.l ? "red" : "" }}>
                size L
              </FormLabel>
              <TextField
                id="standard-bare"
                variant="outlined"
                placeholder="ราคา"
                value={inputSizes.l}
                name="l"
                onChange={handleChangePriceSize}
                InputProps={{
                  sx: {
                    height: "45px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    py: "8px",
                    fontSize: "14px",
                  },
                }}
              />
            </FormControl>
            {sizeError.l && (
              <FormLabel sx={{ color: "red" }}>* required</FormLabel>
            )}
          </Grid>
        )}
        {togglePrice === "ตามไซต์" && (
          <Grid item sm={3} md={3}>
            <FormControl fullWidth>
              <FormLabel sx={{ color: sizeError.xl ? "red" : "" }}>
                size XL
              </FormLabel>
              <TextField
                id="standard-bare"
                variant="outlined"
                placeholder="ราคา"
                value={inputSizes.xl}
                name="xl"
                onChange={handleChangePriceSize}
                InputProps={{
                  sx: {
                    height: "45px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    py: "8px",
                    fontSize: "14px",
                  },
                }}
              />
            </FormControl>
            {sizeError.xl && (
              <FormLabel sx={{ color: "red" }}>* required</FormLabel>
            )}
          </Grid>
        )}

        <Grid item sm={12} md={12}>
          <div className="App inline w-full" ref={editorRef}>
            <FormLabel>คำอธิบาย</FormLabel>
            {editorLoaded && (
              <CKEditor
                editor={ClassicEditor}
                data={descriptionRef}
                ref={descriptionRefValue}
                onReady={(editor: any) => {
                  editor.ui.view.editable.element.style.height = "250px";
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event) => {
                  console.log(event);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => {
                  editProduct();
                }}
              >
                <EditOutlined fontSize={"small"}></EditOutlined>แก้ไขสินค้า
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                fullWidth
                variant={"contained"}
                color={"error"}
                onClick={() => {
                  deleteProduct();
                }}
              >
                <DeleteOutline fontSize={"small"}> </DeleteOutline> ลบสินค้า
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProduct;
