import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";
// import hero from "../image/Home/imghero.png";
import hero from "../image/Home/hero2.jpg";
import mask2 from "../image/maskwhite.jpg";
import { LocalMall, ShoppingBagOutlined } from "@mui/icons-material";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import HomeFilterByCategories from "../component/Product/HomeFilterbyCategories";
import Product from "./Product";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// import TagDiscont from '../component/TagDiscont';
import TagDiscont from "../component/TagDisconthome";
import TagDisconthome from "../component/TagDisconthome";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import mask from "../image/หน้ากาก LV.1/mask01.jpg";
import maskwhite from "../image/หน้ากาก LV.1/maskwhite.jpg";

function Promotion() {
  const params = useParams();
  const Navigate = useNavigate();
  const prod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const discont = "30";
  const navigate = useNavigate();

  const context: any = useOutletContext();
  const [categories, setCategories] = useState<any>([]);
  const [listProducts, setListProducts] = useState<any>([]);
  const [allProducts, setAllproducts] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<string>("");

  const CardCustomProd: any = styled(Box)(({ theme }) => ({
    padding: "12px 0",
    fontSize: "24px",
    borderRadius: "16px",
    flexShrink: "0",
    textAlign: "center",
    margin: "0 auto",
    userSelect: "none",
    fontFamily: theme.typography.fontFamily,
    border: "1px solid #DEDEDE",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      width: "45%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "220px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "240px",
    },
  }));

  useEffect(() => {
    setAllproducts(context[0].allProducts);
    setCategories(context[0].categories);

    const findIndex = context[0].categories.findIndex((element: any) => {
      return element.id == params.id;
    });

    if (findIndex !== -1) {
      setCategoryName(context[0].categories[findIndex].name);
    }
  }, [params]);

  const TypoGraphyHead = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    margin: "0 auto",
    fontWeight: 500,
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "32px",
    },
  }));

  const TypoGraphyTitlepro = styled(Typography)(({ theme }) => ({
    margin: "0 auto",
    // textIndent: "12px",
    textAlign: "left",
    fontcolor: "background: rgba(10, 58, 34, 1)",
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "27px",
    },
  }));

  const filteredCategories = categories
    .filter((category: any) => category.count >= 3)
    .slice(0, 4);

  const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
    margin: "0",
    textIndent: "24px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  }));
  const Containervontact = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingBottom: "24px",
  }));
  return (
    <Grid container flexDirection="column" gap="24px">
      {/* <Grid container sx={{ px: "4%" }}> */}
      <Grid container sx={{ px: "3%", py: "1%" }}>
        <img
          className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
          src={context[0].bannerYT.banner.replace(
            "{api_path}",
            process.env.REACT_APP_API_URL
          )}
          alt="hero1"
        />
      </Grid>
      <Grid className="md:pt-14 justify-start px-[4%]">
        <TypoGraphyHead>สินค้าช่วงโปรโมชั่น</TypoGraphyHead>
      </Grid>
      {/* <Grid className="w-full inline justify-center px-[4%]">
        {filteredCategories.map((category: any, index: number) => (
          <HomeFilterByCategories
            key={index}
            category={category}
            allProducts={allProducts}
            showHeader={false}
          />
        ))}
        {filteredCategories.map((category: any, index: number) => (
          <HomeFilterByCategories
            key={index}
            category={category}
            allProducts={allProducts}
            showHeader={false}
          />
        ))}
        <br />
      </Grid> */}
      {/* <Containervontact> */}
      <Grid container className=" gap-11 py-10">
        <Grid container gap="16px" maxWidth="1650px" sx={{ margin: "0 auto" }}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {prod.map((item, index) => {
              return (
                <SwiperSlide className="p-2" key={index}>
                  <CardCustomProd
                    sx={{ width: { xs: "95%", lg: "90%" }, border: "" }}
                  >
                    <Container>
                      <Box sx={{ position: "relative" }}>
                        <img src={mask2} width="100%" />
                        <TagDisconthome discont={`20`} />
                      </Box>

                      <div className="text-left mt-2">
                        <p className="text-base font-light md:text-xl text-left">
                          <b>U-GUARD MASK</b>
                        </p>
                        <p className="text-[60%] md:text-[70%] font-light">
                          <b>หน้ากากอนามัย 3 ชั้น (สีขาว) LV.1</b>
                        </p>
                      </div>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="pb-4"
                      >
                        <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                          ฿ 65
                        </p>
                        <span
                          className="text-xs font-light md:text-base relative text-end w-full text-gray-500"
                          style={{ flex: 1, textAlign: "right" }}
                        >
                          ขายแล้ว (1 ชิ้น)
                        </span>
                      </Grid>

                      <Button
                        className="items-center"
                        sx={{ boxShadow: 2, borderRadius: 5 }}
                        onClick={() => {
                          navigate(`/product_detail/${item}`);
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        <div className="px-2 items-center flex text-md">
                          <ShoppingBagOutlined
                            sx={{
                              fontSize: { xs: "14px", md: "20px" },
                              mx: "2px",
                            }}
                          />
                          หยิบใส่ตะกร้า
                        </div>
                      </Button>
                    </Container>
                  </CardCustomProd>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid>
      {/* </Containervontact> */}
    </Grid>
  );
}

export default Promotion;
function setAllproducts(allProducts: any) {
  throw new Error("Function not implemented.");
}
