import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Container,
  styled,
} from "@mui/material";
import MaskImg from "../assets/images/mask.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReactPlayer from "react-player/youtube";
import { Product } from "../component/Product";
import ButtonCal from "../component/ButtonCal";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import React from "react";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
// import parse from 'html-react-parser';
import { marked } from "marked";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { LocalMall, ShoppingBagOutlined } from "@mui/icons-material";
import { axiosService } from "../services/axios";
import { stringify } from "querystring";
import TagDisconthome from "../component/TagDisconthome";
import mask2 from "../image/maskwhite.jpg";
import "swiper/css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const imgBoxL = 12 / 5;
const imgBoxS = 12 / 3;

const CardCustomProdpro = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  fontSize: "22px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    width: "90%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "90%",
  },
}));

// const CardCustomProd: any = styled(Box)(({ theme }) => ({
//     padding: "12px 0",
//     fontSize: "24px",
//     borderRadius: "16px",
//     flexShrink: "0",
//     textAlign: "center",
//     margin: "0 auto",
//     userSelect: "none",
//     width: "100%",
//     fontFamily: theme.typography.fontFamily,
//     // border: "1px solid #DEDEDE",
//     position: "relative",
//     [theme.breakpoints.up("xs")]: {
//       width: "45%",
//     },
//     [theme.breakpoints.up("sm")]: {
//       width: "200px",
//     },
//     [theme.breakpoints.up("lg")]: {
//       width: "220px",
//     },
//     [theme.breakpoints.up("xl")]: {
//       width: "240px",
//     },
//   }));

const CardCustomProd: any = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  fontSize: "24px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "220px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "240px",
  },
}));

export default function ProductDetail() {
  const cookies = new Cookies(null, { path: "/" });
  const context: any = useOutletContext();
  const parse = require("html-react-parser").default;
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up("sm"));
  const [value, setValue] = useState("1");

  const prod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [quantity, setQuantity] = useState<any>(1);
  const [productCurrentPriceId, setProductCurrentPriceId] = useState<any>([]);
  const [productDetail, setProductDetail] = useState<any>({});
  const [productPrice, setProductPrice] = useState<any>([]);
  const [productImg, setProductImg] = useState<any>([]);
  const [productPriceShow, setProductPriceShow] = useState<any>([]);
  const [productPriceNameShow, setProductPriceNameShow] = useState<any>([]);
  const [wishlisted, setWishlisted] = useState<boolean>(false);
  const [stock, setStock] = useState<any>(0);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [productDescription, setProductDescription] = useState<any>(null);

  const [productSize, setProductSize] = useState<any>({});

  const [size, setSize] = useState('m');

  const handleChange = (event: SelectChangeEvent) => {
    setSize(event.target.value as string);
  };

  console.log(productSize);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/id/${params.id}`)
      .then((response) => {
        const data = response.data;
        if (data.message === "FOUND") {
          setProductSize(data.priceOfProduct[0]);
          setProductDetail({
            ...data.product[0],
            stock: data.lowPrice.stock,
            price: data.lowPrice.price,
          });

          setProductPrice(data.priceOfProduct);
          setProductImg(data.imageOfProduct);

          if (data.lowPrice.id != 0) {
            setProductPriceNameShow(data.lowPrice.name);
            setProductPriceShow(
              parseFloat(data.lowPrice.price).toLocaleString("en-US")
            );
            setProductCurrentPriceId(data.lowPrice.id);
            setStock(data.lowPrice.stock);
          } else {
            setProductPriceNameShow("ยังไม่มีให้เลือกชนิด");
            setProductPriceShow("0.00");
            setProductCurrentPriceId(0);
            setStock(0);
          }
        } else {
          navigate("/");
        }
      });

    setWishlisted(false);
    // console.log(context[0]);
  }, []);

  // const addToCart = () => {
  //     try {
  //         const service = new axiosService();
  //         service.post('/products/addtocart', {
  //             product_id: params.id,
  //             product_price_id: productCurrentPriceId,
  //             quantity: parseInt(quantity)
  //         }, {
  //             headers: {
  //                 token: cookies.get('token')
  //             }
  //         }).then((response) => {
  //             if(response.status === 200 && response.data.message === "success")
  //             {
  //                 toast.success("เพิ่มเข้าตะกร้าเรียบร้อยแล้ว");
  //                 context[0].reloadCarts();
  //             }
  //             else
  //             {
  //                 toast.error(response.data.message || "Unknow error");
  //                 context[0].reloadCarts();
  //             }
  //         })
  //     } catch (err) {
  //         console.log(err);
  //     }
  // }

  console.log(size)
  const addToCart = () => {
    try {
      const token = cookies.get("token");

      if (!token) {
        // ถ้าไม่มี token ให้เปลี่ยนเส้นทางไปที่หน้า login
        navigate("/login");
        return;
      }

      const service = new axiosService();
      service
        .post(
          "/products/addtocart",
          {
            product_id: params.id,
            product_price_id: productCurrentPriceId,
            quantity: parseInt(quantity),
            size: productSize.is_size !== 'single_price' ? size : null
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.message === "success") {
            toast.success("เพิ่มเข้าตะกร้าเรียบร้อยแล้ว");
            context[0].reloadCarts();
          } else {
            toast.error(response.data.message || "Unknow error");
            context[0].reloadCarts();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  function escapeMarkdownSpecialChars(description: string): string {
    return description
      .replace(/^- /gm, "\\- ")
      .replace(/\* /gm, "\\* ")
      .replace(/_ /gm, "\\_ ")
      .replace(/` /gm, "\\` ");
  }

  // const changeCurrentPrice = (price_id:any) => {
  //     const find_idx = productPrice.findIndex((price:any) => {
  //         return price.value == price_id
  //     });
  //     setProductCurrentPriceId(productPrice[find_idx].value);
  //     setProductPriceShow(parseFloat(productPrice[find_idx].price).toLocaleString('en-US'));
  //     setStock(productPrice[find_idx].stock)

  //     setProductPriceNameShow(productPrice[find_idx].name);
  // }

  const setQuantityData = (e: any, data?: any) => {
    setQuantity(e);
    setProductPriceShow(
      (parseFloat(productDetail.price) * e).toLocaleString("en-US")
    );
  };

  return (
    <Box
      sx={{
        paddingBottom: { md: 0, xs: 10 },
        paddingX: { md: "5%", xs: 0 },
        paddingTop: { md: 5, xs: 0 },
      }}
    >
      <Toaster />
      <Card
        elevation={2}
        sx={{
          padding: 2,
          borderRadius: 10,
        }}
      >
        <CardContent>
          <Grid container columnSpacing={1}>
            <Grid item md={6}>
              <Grid container justifyContent={"center"}>
                {productImg.length > 0 ? (
                  <Grid
                    item
                    lg={8}
                    md={12}
                    xs={12}
                    sx={{
                      // border: '1px solid #ccc',
                      // borderRadius: 3

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      border: "1px solid #ccc",
                      borderRadius: 3,
                      textAlign: "center",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src={productImg[selectedImage].path.replace(
                        `{api_path}`,
                        process.env.REACT_APP_API_URL
                      )}
                      alt=""
                      style={{
                        // borderRadius: 10
                        borderRadius: 10,
                        maxWidth: "80%",
                        maxHeight: "80%",
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    lg={8}
                    md={12}
                    xs={12}
                    sx={{
                      // border: '1px solid #ccc',
                      // borderRadius: 3

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      border: "1px solid #ccc",
                      borderRadius: 3,
                      textAlign: "center",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/404-not-found.png`}
                      alt=""
                      style={{
                        // borderRadius: 10
                        borderRadius: 10,
                        maxWidth: "80%",
                        maxHeight: "80%",
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={12} textAlign={"center"} mt={5}>
                  <Grid container md={12} spacing={3}>
                    {productImg.map((img: any, index: number) => {
                      return (
                        <Grid
                          item
                          key={index}
                          md={imgBoxL}
                          sm={imgBoxS}
                          xs={imgBoxS}
                        >
                          <img
                            src={img.path.replace(
                              `{api_path}`,
                              process.env.REACT_APP_API_URL
                            )}
                            alt=""
                            onClick={() => {
                              setSelectedImage(index);
                            }}
                            style={{
                              borderRadius: 5,
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} className="p-5">
              <Box>
                <Typography variant={"h5"}>{productDetail.name}</Typography>
                {/* <Typography variant={'subtitle1'} color="#ccc">
                                    รหัส: {productDetail.code}
                                </Typography>
                                <Typography variant={'subtitle1'} color="#ccc">
                                    ขายแล้ว 1 ชิ้น&nbsp;
                                    <small>
                                        (สินค้าคงเหลือ: {productDetail.stock} ชิ้น)
                                    </small>
                                </Typography> */}
                <Typography variant={"h3"}>฿{productSize?.is_size != 'price_by_size' ? productPriceShow :
                productSize[size]}
                
                </Typography>
                <br></br>

                {productSize?.is_size != 'single_price' && <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  <Typography variant={"h6"} color="#000000" paddingY={2}>
                    เลือก Size
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={size}
                      label="Size"
                      onChange={handleChange}
                      defaultValue="m"
                    >
                      <MenuItem value="s">S</MenuItem>
                      <MenuItem value="m">M</MenuItem>
                      <MenuItem value="l">L</MenuItem>
                      <MenuItem value="xl">XL</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                  }
                <br></br>
                <Typography variant={"h6"} color="#000000">
                  จำนวน
                </Typography>
                <br />
                <Grid className="flex flex-wrap gap-3">
                  <ButtonCal
                    data={productDetail}
                    getSubTotal={setQuantityData}
                    enableRemove={false}
                  ></ButtonCal>
                  <br></br>
                  <br></br>
                  <Box>
                    <Button
                      className="md:w-[238px] h-[45px] w-[150px]"
                      variant="contained"
                      onClick={addToCart}
                    >
                      หยิบใส่ตระกร้า
                    </Button>
                  </Box>
                </Grid>
                <br></br>
                <br></br>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={() => {
                        // handleChange
                      }}
                      aria-label="basic tabs example"
                    >
                      <Tab label="รายละเอียดสินค้า" value="1" />
                      <Tab label="รายละเอียดเพิ่มเติม" value="2" />
                    </Tabs>
                  </Box>
                  <TabPanel value="1">
                    <Typography
                      variant="body1"
                      className="md:pr-10 items-start text-left"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {productDetail.description != null &&
                        parse(
                          marked.parse(
                            escapeMarkdownSpecialChars(
                              productDetail.description
                            )
                          )
                        )}
                    </Typography>
                  </TabPanel>

                  <TabPanel value="2">Item Two</TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
          <br></br>

          <ReactPlayer
            controls
            playIcon={<PlayArrowIcon />}
            url={context[0].bannerYT.youtube}
            width={"100%"}
            height={"600px"}
          />
        </CardContent>
      </Card>
      <br></br>
      <Typography variant="h4" className="pt-10 md:0 px-[1%]">
        สินค้าที่เกี่ยวข้อง
      </Typography>

      {/* <Grid container spacing={3} style={{ padding: 20 }}>
  {
    [1, 2, 3, 4].map((item, index) => (
      <Grid  item key={index} md={3} sm={6} xs={12}>
        <Product />
      </Grid>
    ))
  }
</Grid> */}

      {/* <Grid container className=" gap-11 py-10">
        <Grid container gap="16px" maxWidth="1650px" sx={{ margin: "0 auto",paddingX:{md:"5%",xs:} }}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {context[0].allProducts &&
              context[0].allProducts.map((item: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <CardCustomProdpro>
                      <div className="px-5">
                        <Box sx={{ position: "relative" }}>
                          <img
                            src={
                              item.image?.replace(
                                "{api_path}",
                                process.env.REACT_APP_API_URL
                              ) || "/404-not-found.png"
                            }
                            className="w-full h-[150px] md:h-[270px] object-contain"
                            alt={item.name}
                          />
                          <TagDisconthome discont={`20`} />
                        </Box>
                        <div className="text-center">
                          <div className="text-left mt-2">
                            <p className="text-xs font-light md:text-lg text-left h-12">
                              <b>{item.name}</b>
                            </p>
                          </div>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className="pb-4 pt-2"
                          >
                            <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                              ฿ {item.price}
                            </p>
                            <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                              ขายแล้ว (1 ชิ้น)
                            </span>
                          </Grid>
                          <Button
                            className="items-center"
                            sx={{ boxShadow: 2, borderRadius: 5 }}
                            onClick={() => {
                              navigate(`/product_detail/${item.id}`);
                              window.scrollTo({ top: 0 });
                            }}
                          >
                            <div className="px-2 items-center flex text-md">
                              <ShoppingBagOutlined
                                sx={{
                                  fontSize: { xs: "14px", md: "20px" },
                                  mx: "2px",
                                }}
                              />
                              หยิบใส่ตะกร้า
                            </div>
                          </Button>
                        </div>
                      </div>
                    </CardCustomProdpro>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Grid>
      </Grid> */}
      <Grid container className="gap-11 py-10">
        <Grid
          container
          gap="16px"
          maxWidth="1650px"
          sx={{ margin: "0 auto", paddingX: { xs: "1%", md: 0 } }}
        >
          <Swiper
            modules={[Autoplay]}
            // loop={true}
            // autoplay={{
            //   delay: 3,
            //   pauseOnMouseEnter: true,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {context[0].allProducts &&
              context[0].allProducts.map((item: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <CardCustomProdpro>
                      <div className="px-5">
                        <Box sx={{ position: "relative" }}>
                          <img
                            src={
                              item.image?.replace(
                                "{api_path}",
                                process.env.REACT_APP_API_URL
                              ) || "/404-not-found.png"
                            }
                            className="w-full h-[150px] md:h-[270px] object-contain"
                            alt={item.name}
                          />
                          <TagDisconthome discont={`20`} />
                        </Box>
                        <div className="text-center">
                          <div className="text-left mt-2">
                            <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                              <b>{item.name}</b>
                            </p>
                          </div>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className="pb-4 pt-2"
                          >
                            <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                              ฿ {item.price}
                            </p>
                            <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                              ขายแล้ว (1 ชิ้น)
                            </span>
                          </Grid>
                          <Button
                            className="items-center"
                            sx={{ boxShadow: 2, borderRadius: 5 }}
                            onClick={() => {
                              navigate(`/product_detail/${item.id}`);
                              window.scrollTo({ top: 0 });
                            }}
                          >
                            <div className="px-2 items-center flex text-md">
                              <ShoppingBagOutlined
                                sx={{
                                  fontSize: { xs: "14px", md: "20px" },
                                  mx: "2px",
                                }}
                              />
                              หยิบใส่ตะกร้า
                            </div>
                          </Button>
                        </div>
                      </div>
                    </CardCustomProdpro>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
}
