import React, { useEffect, useRef, useState } from "react";
// import hero from "../image/Home/imghero.png";
import hero from "../image/Home/hero2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import mask from "../image/หน้ากาก LV.1/mask01.jpg";
import gloves from "../image/gloves/gloves.png";
import mobcap from "../image/mobcap/mobcap.png";
import Logo from "../image/about/logo.png";
import logo from "../image/S__50708522_0.jpg";
import logo03 from "../image/101.png";
import cer1 from "../assets/images/cer1.jpg";
import cer2 from "../assets/images/cer2.jpg";
import cer3 from "../assets/images/cer3.jpg";
import cer4 from "../assets/images/cer4.jpg";
import mask2 from "../image/maskwhite.jpg";
import image2 from "../image/image2.png";
import image3 from "../image/image3.png";
import image4 from "../image/image4.png";
import image5 from "../image/image5.png";
import image6 from "../image/image6.png";
import image7 from "../image/image7.png";
import image8 from "../image/image8.png";
import TagDisconthome from "../component/TagDisconthome";
import { LocalMall, ShoppingBagOutlined } from "@mui/icons-material";
import axios from "axios";

import no1level1 from "../image/Pic หน้ากาก แบบยกลัง/level1.jpg";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import shoppee from "../assets/images/shoppelogo.png";
import lazada from "../assets/images/lazadalogo.png";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaLine } from "react-icons/fa";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
  useTheme,
  Button,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";
import "swiper/css";
import HomeFilterByCategories from "../component/Product/HomeFilterbyCategories";
import AboutUs from "../component/about/AboutUs";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";
import Product from "./Product";
import Swal from "sweetalert2";
import imag404 from "../../public/404-not-found.png";

const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textIndent: "12px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const TypoGraphyTitlepro = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  // textIndent: "12px",
  textAlign: "left",
  fontcolor: "background: rgba(10, 58, 34, 1)",
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "27px",
  },
}));

const TypoGraphyTitleCategory = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    fontSize: "8px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));
const TypoGraphyHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "0 auto",
  fontWeight: 500,
  wordWrap: "break-word",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "28px",
  },
}));

const CardCustom = styled(Box)(({ theme }) => ({
  fontSize: "36px",
  borderRadius: "16px",
  textAlign: "center",
  // margin: "0 auto",
  fontFamily: theme.typography.fontFamily,
  // border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    width: "90px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "130px",
  },
  [theme.breakpoints.up("md")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "250px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "300px",
  },
}));
const CardCustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "500px",
  color: "#2D2C2C",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.down("sm")]: {
    width: "250px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));
const CardCustomProd = styled(Box)(({ theme }) => ({
  flexShrink: "0",
  padding: "5px",
  fontSize: "24px",
  borderRadius: "16px",
  textAlign: "center",
  margin: "0 auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  userSelect: "none",
  [theme.breakpoints.up("xs")]: {
    width: "140px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "220px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "240px",
  },
}));

const BoxCustomBand = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const Img = styled("img")(({ theme }) => ({}));
const Containervontact = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

const imageData = [
  {
    src: mask2,
    titleThai: "หน้ากากอนามัย",
    titleEng: "MEDICAL MASK",
    link: "/shop/1",
  },
  {
    src: image2,
    titleThai: "หมวกคลุมผม",
    titleEng: "MOB CAP",
    link: "/shop/1",
  },
  {
    src: image3,
    titleThai: "ถุงมือยางธรรมชาติ",
    titleEng: "GLOVES",
    link: "/shop/1",
  },
  {
    src: image4,
    titleThai: "เข็มและกระบอกฉีดยา",
    titleEng: "",
    link: "/shop/1",
  },
  { src: image5, titleThai: "ถุงมือผ่าตัด", titleEng: "", link: "/shop/1" },
  {
    src: image7,
    titleThai: "เข็มและอุปกรณ์ผ่าตัด",
    titleEng: "",
    link: "/shop/1",
  },
  { src: image6, titleThai: "สำลีและผ้าก็อต", titleEng: "", link: "/shop/1" },
  { src: image8, titleThai: "อื่นๆ", titleEng: "", link: "/shop/1" },
];

interface Category {
  id: number;
  name: string;
  en_name: string;
  image_path: string;
  count: number;
}

const Home = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const Navigate = useNavigate();
  const Theme = useTheme();
  const baseURL = "https://api.medwholesale.co";
  const notFoundImage = "../../public/404-not-found.png";
  const cer = [
    {
      image: cer1,
    },
    {
      image: cer2,
    },
    {
      image: cer3,
    },
    {
      image: cer4,
    },
  ];
  const context: any = useOutletContext();
  // const [categories, setCategories] = useState<any>([]);
  const [allProducts, setAllproducts] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCategories(context[0].categories);
    setAllproducts(context[0].allProducts);
  }, []);

  useEffect(() => {
    axios
      .get("https://api.medwholesale.co/categories/All")
      .then((response) => {
        const data = response.data.map((item: Category) => ({
          ...item,
          image_path: item.image_path
            ? item.image_path.replace("{api_path}", baseURL)
            : notFoundImage,
        }));
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  }, []);

  const prod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // sx={{position:"sticky",left:'0', right: '5px', top: '50%'}}
  return (
    <Grid
      container
      flexDirection="column"
      gap={{ xs: 2, sm: "45px", md: "50px", lg: "62px" }}
    >
      {/* <Grid container sx={{ px: "4%" }}>
        <img className="pt-5" src={context[0].bannerYT.banner.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )} alt="hero" width="100%" />
      </Grid> */}
      <Grid container sx={{ px: "3%", py: "1%" }}>
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          spaceBetween={30}
          slidesPerView={1}
        >
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
              src={context[0].bannerYT.banner.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
              src={context[0].bannerYT.banner2.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
              src={context[0].bannerYT.banner3.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero3"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
              src={context[0].bannerYT.banner4.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero4"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover "
              src={context[0].bannerYT.banner5.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero5"
            />
          </SwiperSlide>
        </Swiper>
      </Grid>

      <Grid
        className="flex justify-center "
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <div>
          <hr className="w-[308px] h-1 bg-[#134C30] text-green-700 " />
        </div>
      </Grid>
      <Grid>
        <Container>
          <TypoGraphyHead
            className="flex justify-center items-center w-full"
            sx={{ textAlign: "center" }}
          >
            ศูนย์รวมสินค้าสำหรับดูแลผู้สูงอายุ ที่หลากหลาย พร้อมให้บริการด้วยความเชี่ยวชาญและความเอาใจใส่
            <br />
            ด้วยคำแนะนำที่ถูกต้องและอบอุ่นต่อลูกค้าทุกคน   
          </TypoGraphyHead>
          <TypoGraphyTitle
            sx={{
              width: "100%",
              marginTop: "10px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            เราจึงไม่หยุดพัฒนาบุคลากร รวมถึงการคัดสรรสินค้าที่มีคุณภาพ ราคาเหมาะสม เพื่อให้ลูกค้าของเรามั่นใจ วางใจในผลิตภัณฑ์และบริการของเรา อาทิเช่น เตียงผู้ป่วย เตียงผู้ป่วยไฟฟ้า ที่นอนลมป้องกันแผลกดทับ รถเข็นไฟฟ้า วีลแชร์ เก้าอี้นั่งถ่าย เครื่องวัดความดันโลหิต เครื่องผลิตออกซิเจน เครื่องตรวจเบาหวาน ฯลฯ
            นอกจากนี้ฟาซิแคร์ยังมีโชว์รูมให้ลูกค้าเข้ามาทดลองใช้สินค้าพร้อมเจ้าหน้าที่ให้คำปรึกษา มีทีมขนส่งของเราเอง และบริการหลังการขายที่ครบครัน
          </TypoGraphyTitle>
        </Container>
      </Grid>
      <Divider className="px-[5%]">
        <CardCustomHead className="opacity-100">หมวดหมู่สินค้า</CardCustomHead>
      </Divider>

      {/* test1  */}
      {/* <Grid
        container
        gap={{ xs: "0", md: "16px" }}
        maxWidth="1670px"
        justifyContent={"space-around"}
        sx={{ margin: "0 auto" }}
      >
        {categories.length > 0 &&
          categories.map((category: any, index: number) => {
            return (
              <CardCustom
                key={index}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  Navigate(`/shop/${category.id}`);
                  window.scrollTo({ top: 0 });
                }}
              >
                <Container>
                  {category.image_path !== null ? (
                    <img
                      src={category.image_path.replace(
                        `{api_path}`,
                        process.env.REACT_APP_API_URL
                      )}
                      width="100%"
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/404-not-found.png`}
                      width="100%"
                    />
                  )}
                  <TypoGraphyTitle>{category.name}</TypoGraphyTitle>
                </Container>
              </CardCustom>
            );
          })}
      </Grid> */}

      {/* <Grid container justifyContent="center" className="md:px-16">
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {categories.length > 0 &&
            categories.map((category: any, index: number) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <CardCustom
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Navigate(`/shop/${category.id}`);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    <Container>
                      {category.image_path !== null ? (
                        <img
                          src={category.image_path.replace(
                            `{api_path}`,
                            process.env.REACT_APP_API_URL
                          )}
                          width="100%"
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/404-not-found.png`}
                          width="100%"
                        />
                      )}
                      <img className="pt-5 w-full" src={mask2} alt="" />
                      <TypoGraphyTitle>{category.name}</TypoGraphyTitle>
                    </Container>
                  </CardCustom>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Grid> */}

      {/* test1  */}

      {/* ไม่มี back */}
      <Grid container justifyContent="center" className="md:px-16">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {categories.map((item, index) => (
            <SwiperSlide
              key={item.id}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CardCustom
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/shop/${item.id}`);
                  window.scrollTo({ top: 0 });
                }}
              >
                <Container>
                  <img
                    className="mb-1 h-[70px] sm:h-[130px] md:h-[150px] lg:h-[250px] object-contain"
                    src={item.image_path}
                    alt=""
                  />
                  <TypoGraphyTitleCategory style={{ color: "#0A3A22" }}>
                    {item.en_name}
                  </TypoGraphyTitleCategory>
                  <TypoGraphyTitleCategory style={{ color: "#0A3A22" }}>
                    {item.name}
                  </TypoGraphyTitleCategory>
                </Container>
              </CardCustom>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>

      <Divider className="px-[5%]">
        <CardCustomHead className="opacity-100">Certificate</CardCustomHead>
      </Divider>
      <Grid className="md:px-16" container justifyContent={"space-evenly"}>
        <Swiper
          className="sample-slider"
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 3,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          grabCursor={false}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            600: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 4,
            },
          }}
          speed={3000}
        >
          {cer.map((img) => (
            <SwiperSlide>
              <Img
                src={img.image}
                width={"95%"}
                height={"100%"}
                className="h-[400px] object-contain"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>

      {/* <Grid container className=" gap-11 md:px-14">
        {categories.map(
          (category: any) =>
            category.count >= 3 && (
              <HomeFilterByCategories
                category={category}
                name={"MEDICAL MASK\nหน้ากากอนามัย"}
                allProducts={allProducts}
              />
            )
        )}
        {categories.map(
          (category: any) =>
            category.count >= 3 && (
              <HomeFilterByCategories
                category={category}
                name={"MOB CAP\nหมวกคลุมผม"}
                allProducts={allProducts}
              />
            )
        )}
        {categories.map(
          (category: any) =>
            category.count >= 3 && (
              <HomeFilterByCategories
                category={category}
                name={"GLOVES\nถุงมือยางธรรมชาติ"}
                allProducts={allProducts}
              />
            )
        )}
        {categories.map(
          (category: any) =>
            category.count >= 3 && (
              <HomeFilterByCategories
                category={category}
                name={"เข็มและกระบอกฉีดยา"}
                allProducts={allProducts}
              />
            )
        )}
      </Grid> */}

      {/* <Grid container className=" gap-11 lg:pt-10">
        <Grid className="w-full p-2 md:px-[5%] m-0 flex items-center relative md:my-12 my-0">
          <TypoGraphyTitlepro
            sx={{ lineHeight: { md: "40px", xs: "20px" } }}
            className="p-3 px-5 justify-center border-2 bg-white font-bold rounded-lg h- items-center md:text-2xl text-base absolute flex whitespace-pre-line text-[#0A3A22] "
          >
            {"MEDICAL MASK\nหน้ากากอนามัย"}
          </TypoGraphyTitlepro>
          <Divider
            className="px-10 w-full"
            sx={{ display: { md: "flex", xs: "none" } }}
          />
        </Grid>
        <Grid container gap="16px" maxWidth="1650px" sx={{ margin: "0 auto" }}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {prod.map((item, index) => {
              return (
                <SwiperSlide className="p-2" key={index}>
                  <CardCustomProd
                    sx={{ width: { xs: "95%", lg: "90%" }, border: "none" }}
                  >
                    <Container>
                      <Box sx={{ position: "relative" }}>
                        <img src={mask2} width="100%" />
                        <TagDisconthome discont={`20`} />
                      </Box>

                      <div className="text-left mt-2">
                        <p className="text-base font-light md:text-xl text-left">
                          <b>U-GUARD MASK</b>
                        </p>
                        <p className="text-[60%] md:text-[70%] font-light">
                          <b>หน้ากากอนามัย 3 ชั้น (สีขาว) LV.1</b>
                        </p>
                      </div>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="pb-4"
                      >
                        <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                          ฿ 65
                        </p>
                        <span
                          className="text-xs font-light md:text-base relative text-end w-full text-gray-500"
                          style={{ flex: 1, textAlign: "right" }}
                        >
                          ขายแล้ว (1 ชิ้น)
                        </span>
                      </Grid>

                      <Button
                        className="items-center"
                        sx={{ boxShadow: 2, borderRadius: 5 }}
                        onClick={() => {
                          navigate(`/product_detail/${item}`);
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        <div className="px-2 items-center flex text-md">
                          <ShoppingBagOutlined
                            sx={{
                              fontSize: { xs: "14px", md: "20px" },
                              mx: "2px",
                            }}
                          />
                          หยิบใส่ตะกร้า
                        </div>
                      </Button>
                    </Container>
                  </CardCustomProd>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid>

      <Grid container className=" gap-11 lg:pt-10 pt-10">
        <Grid className="w-full p-2 md:px-[5%] m-0 flex items-center relative md:my-12 my-0">
          <TypoGraphyTitlepro
            sx={{ lineHeight: { md: "40px", xs: "20px" } }}
            className="p-3 px-5 justify-center border-2 bg-white font-bold rounded-lg h- items-center md:text-2xl text-base absolute flex whitespace-pre-line text-[#0A3A22] "
          >
            {"MOB CAP\nหมวกคลุมผม"}
          </TypoGraphyTitlepro>
          <Divider
            className="px-10 w-full"
            sx={{ display: { md: "flex", xs: "none" } }}
          />
        </Grid>
        <Grid container gap="16px" maxWidth="1650px" sx={{ margin: "0 auto" }}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {prod.map((item, index) => {
              return (
                <SwiperSlide className="p-2" key={index}>
                  <CardCustomProd
                    sx={{ width: { xs: "95%", lg: "90%" }, border: "none" }}
                  >
                    <Container>
                      <Box sx={{ position: "relative" }}>
                        <img src={image2} width="100%" />
                        <TagDisconthome discont={`20`} />
                      </Box>

                      <div className="text-left mt-2">
                        <p className="text-base font-light md:text-xl text-left">
                          <b>MOB CAP</b>
                        </p>
                        <p className="text-[60%] md:text-[70%] font-light">
                          <b>nหมวกคลุมผม LV.1</b>
                        </p>
                      </div>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="pb-4"
                      >
                        <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                          ฿ 65
                        </p>
                        <span
                          className="text-xs font-light md:text-base relative text-end w-full text-gray-500"
                          style={{ flex: 1, textAlign: "right" }}
                        >
                          ขายแล้ว (1 ชิ้น)
                        </span>
                      </Grid>

                      <Button
                        className="items-center"
                        sx={{ boxShadow: 2, borderRadius: 5 }}
                        onClick={() => {
                          navigate(`/product_detail/${item}`);
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        <div className="px-2 items-center flex text-md">
                          <ShoppingBagOutlined
                            sx={{
                              fontSize: { xs: "14px", md: "20px" },
                              mx: "2px",
                            }}
                          />
                          หยิบใส่ตะกร้า
                        </div>
                      </Button>
                    </Container>
                  </CardCustomProd>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid>

      <Grid container className=" gap-11 lg:pt-10 pt-10">
        <Grid className="w-full p-2 md:px-[5%] m-0 flex items-center relative md:my-12 my-0">
          <TypoGraphyTitlepro
            sx={{ lineHeight: { md: "40px", xs: "20px" } }}
            className="p-3 px-5 justify-center border-2 bg-white font-bold rounded-lg h- items-center md:text-2xl text-base absolute flex whitespace-pre-line text-[#0A3A22] "
          >
            {"GLOVES\nถุงมือยางธรรมชาติ"}
          </TypoGraphyTitlepro>
          <Divider
            className="px-10 w-full"
            sx={{ display: { md: "flex", xs: "none" } }}
          />
        </Grid>
        <Grid container gap="16px" maxWidth="1650px" sx={{ margin: "0 auto" }}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            grabCursor={false}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            speed={3000}
          >
            {prod.map((item, index) => {
              return (
                <SwiperSlide className="p-2" key={index}>
                  <CardCustomProd
                    sx={{ width: { xs: "95%", lg: "90%" }, border: "none" }}
                  >
                    <Container>
                      <Box sx={{ position: "relative" }}>
                        <img src={image3} width="100%" />
                        <TagDisconthome discont={`20`} />
                      </Box>

                      <div className="text-left mt-2">
                        <p className="text-base font-light md:text-xl text-left">
                          <b>GLOVES</b>
                        </p>
                        <p className="text-[60%] md:text-[70%] font-light">
                          <b>nถุงมือยางธรรมชาติ LV.1</b>
                        </p>
                      </div>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="pb-4"
                      >
                        <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                          ฿ 65
                        </p>
                        <span
                          className="text-xs font-light md:text-base relative text-end w-full text-gray-500"
                          style={{ flex: 1, textAlign: "right" }}
                        >
                          ขายแล้ว (1 ชิ้น)
                        </span>
                      </Grid>

                      <Button
                        className="items-center"
                        sx={{ boxShadow: 2, borderRadius: 5 }}
                        onClick={() => {
                          navigate(`/product_detail/${item}`);
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        <div className="px-2 items-center flex text-md">
                          <ShoppingBagOutlined
                            sx={{
                              fontSize: { xs: "14px", md: "20px" },
                              mx: "2px",
                            }}
                          />
                          หยิบใส่ตะกร้า
                        </div>
                      </Button>
                    </Container>
                  </CardCustomProd>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid> */}
      {/* {categories?.map((el: any) => {
        const finalProduct = allProducts.filter(
          (categorie: any) => categorie.category_id === el.id
        );

        const finalImage = imageData.filter(
          (image: any) => image.titleThai === el.name
        );

        return (
          <div>
            <Grid container className="gap-11 lg:pt-10 pt-10">
              <Grid className="w-full p-2 md:px-[5%] m-0 flex items-center relative md:my-12 my-0">
                <TypoGraphyTitlepro
                  sx={{ lineHeight: { md: "40px", xs: "20px" } }}
                  className="p-3 px-5 justify-center border-2 bg-white font-bold rounded-lg h- items-center md:text-2xl text-base absolute flex whitespace-pre-line text-[#0A3A22]"
                >
                  {el.name}
                </TypoGraphyTitlepro>
                <Divider
                  className="px-10 w-full"
                  sx={{ display: { md: "flex", xs: "none" } }}
                />
              </Grid>
              <Grid
                container
                gap="16px"
                maxWidth="1650px"
                sx={{ margin: "0 auto" }}
              >
                {finalProduct.length > 0 ? (
                  <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    autoplay={{
                      delay: 3000,
                      pauseOnMouseEnter: true,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    grabCursor={false}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      600: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                      1280: {
                        slidesPerView: 4,
                      },
                    }}
                    speed={3000}
                  >
                    {finalProduct.map((item: any, index: any) => (
                      <SwiperSlide className="p-2" key={index}>
                        <CardCustomProd
                          sx={{
                            width: { xs: "95%", lg: "90%" },
                            border: "none",
                          }}
                        >
                          <Container>
                            <Box sx={{ position: "relative" }}>
                              <div className="w-[300px]">

                              <img
                                src={
                                  item?.path?.replace(
                                    "{api_path}",
                                    process.env.REACT_APP_API_URL
                                  ) || finalImage[0]?.["src"]
                                }
                                className="w-full h-auto max-w-xs mx-auto"
                                alt="image"
                                />
                                </div>
                              <TagDisconthome discont={`20`} />
                            </Box>

                            <div className="text-left mt-2">
                              <p className="text-base font-light md:text-xl text-left">
                                <b>NEEDLE AND SYRINGE</b>
                              </p>
                              <p className="text-[60%] md:text-[70%] font-light">
                                <b>{item.name}</b>
                              </p>
                            </div>

                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="pb-4"
                            >
                              <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                                ฿ {item.price}
                              </p>
                              <span
                                className="text-xs font-light md:text-base relative text-end w-full text-gray-500"
                                style={{ flex: 1, textAlign: "right" }}
                              >
                                ขายแล้ว (1 ชิ้น)
                              </span>
                            </Grid>

                            <Button
                              className="items-center"
                              sx={{ boxShadow: 2, borderRadius: 5 }}
                              onClick={() => {
                                navigate(`/product_detail/${item.id}`);
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <div className="px-2 items-center flex text-md">
                                <ShoppingBagOutlined
                                  sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    mx: "2px",
                                  }}
                                />
                                หยิบใส่ตะกร้า
                              </div>
                            </Button>
                          </Container>
                        </CardCustomProd>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : null}
              </Grid>
            </Grid>
          </div>
        );
      })} */}

      {categories?.map((el: any, index: number) => {
        const finalProduct = allProducts?.filter(
          (categorie: any) => categorie.category_id === el.id
        );

        const finalImage = imageData?.filter(
          (image: any) => image.titleThai === el.name
        );

        if (finalProduct?.length < 1) return null;

        return (
          <Grid key={index} container className="gap-11 pt-5">
            <Grid
              item
              className="w-full p-2 md:px-[5%] m-0 flex items-center relative md:my-12 my-0"
            >
              <TypoGraphyTitlepro
                sx={{ lineHeight: { md: "40px", xs: "20px" } }}
                className="p-3 px-5 justify-center border-2 bg-white font-bold rounded-lg h- items-center md:text-2xl text-base absolute flex whitespace-pre-line text-[#0A3A22]"
              >
                {el.name}
              </TypoGraphyTitlepro>
              <Divider
                className="px-10 w-full"
                sx={{ display: { md: "flex", xs: "none" } }}
              />
            </Grid>
            <Grid
              container
              gap="16px"
              // maxWidth="1650px"
              width="100%"
              sx={{ margin: "0 auto", paddingX: { md: "5%", xs: "1%" } }}
            >
              {finalProduct?.length > 0 && (
                <Swiper
                  modules={[Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor={false}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    600: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                  speed={3000}
                >
                  {finalProduct.map((item: any, index: any) => (
                    <SwiperSlide key={index} className="p-2">
                      <CardCustomProd
                        sx={{
                          width: { xs: "95%", lg: "90%" },
                          border: "",
                        }}
                      >
                        <Container
                          sx={{
                            paddingLeft: "10px !important",
                            paddingRight: "10px !important",
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <img
                              src={
                                item?.path?.replace(
                                  "{api_path}",
                                  process.env.REACT_APP_API_URL
                                ) || "/404-not-found.png"
                              }
                              className="w-full h-[150px] md:h-[270px] object-contain"
                              alt="product image"
                            />
                            <TagDisconthome discont={`20`} />
                          </Box>

                          <div className="text-left mt-2">
                            <p className="text-base font-light md:text-xl text-left">
                              <b>{/* ชื่อสินค้า */}</b>
                            </p>
                            <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                              <b>{item.name}</b>
                            </p>
                          </div>

                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className="pb-4 pt-2"
                          >
                            <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                              ฿ {item.price}
                            </p>
                            <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                              ขายแล้ว (1 ชิ้น)
                            </span>
                          </Grid>

                          <Button
                            className="items-center"
                            sx={{ boxShadow: 2, borderRadius: 5 }}
                            onClick={() => {
                              navigate(`/product_detail/${item.id}`);
                              window.scrollTo({ top: 0 });
                            }}
                          >
                            <div className="px-2 items-center flex text-md">
                              <ShoppingBagOutlined
                                sx={{
                                  fontSize: { xs: "14px", md: "20px" },
                                  mx: "2px",
                                }}
                              />
                              หยิบใส่ตะกร้า
                            </div>
                          </Button>
                        </Container>
                      </CardCustomProd>
                    </SwiperSlide>
                  ))}
                  {finalProduct.length == 1 &&
                    finalProduct.map((item: any, index: any) => (
                      <SwiperSlide key={index} className="p-2">
                        <CardCustomProd
                          sx={{
                            width: { xs: "95%", lg: "90%" },
                            border: "",
                          }}
                        >
                          <Container
                            sx={{
                              paddingLeft: "10px !important",
                              paddingRight: "10px !important",
                            }}
                          >
                            <Box sx={{ position: "relative" }}>
                              <img
                                src={
                                  item?.path?.replace(
                                    "{api_path}",
                                    process.env.REACT_APP_API_URL
                                  ) || "/404-not-found.png"
                                }
                                className="w-full h-[150px] md:h-[270px] object-contain"
                                alt="product image"
                              />
                              <TagDisconthome discont={`20`} />
                            </Box>

                            <div className="text-left mt-2">
                              <p className="text-base font-light md:text-xl text-left">
                                <b>{/* ชื่อสินค้า */}</b>
                              </p>
                              <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                                <b>{item.name}</b>
                              </p>
                            </div>

                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="pb-4 pt-2"
                            >
                              <p className="text-base font-normal md:text-xl text-left text-[#0A3A22]">
                                ฿ {item.price}
                              </p>
                              <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                                ขายแล้ว (1 ชิ้น)
                              </span>
                            </Grid>

                            <Button
                              className="items-center"
                              sx={{ boxShadow: 2, borderRadius: 5 }}
                              onClick={() => {
                                navigate(`/product_detail/${item.id}`);
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <div className="px-2 items-center flex text-md">
                                <ShoppingBagOutlined
                                  sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    mx: "2px",
                                  }}
                                />
                                หยิบใส่ตะกร้า
                              </div>
                            </Button>
                          </Container>
                        </CardCustomProd>
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}
            </Grid>
          </Grid>
        );
      })}

      {/* ------------------------------------------------------------------------------------- */}
      {/* About New */}
      <div className="w-full flex justify-center">
        <hr className="md:w-[308px] h-1 bg-[#134C30] text-green-700 " />
      </div>

      <div className="-mb-20 md:mb-1">
        <AboutUs />
      </div>
      {/* ------------------------------------------------------------------------------------- */}

      {/* ------------------------------------------------------------------------------------- */}
      {/* contact us New */}
      <Grid
        container
        flexDirection="column"
        gap="24px"
        className="mb-12"
        sx={{ px: "4%" }}
      >
        {/* <Grid container>
          <img src={context[0].bannerYT.banner.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )} alt="hero" width="100%" />
        </Grid> */}
        {/* <Containervontact> */}
        {/* <Grid className="w-full">
            <Divider className="px-[5%]">
              <CardCustomHead>CONTACT US</CardCustomHead>
            </Divider>
          </Grid> */}

        <ContactUsNewVer />
        {/* <Grid
            className="flex-col"
            container
            flexDirection={{ xs: "row", md: "column" }}
            justifyContent="center"
            gap={{ xs: 1, sm: 3 }}
          >
            <Grid
              container
              xs={5}
              md={12}
              flexDirection={{ xs: "column", md: "row", lg: "row" }}
              justifyContent="center"
              gap={{ xs: 1 }}
              alignItems="center"
            >
              <Grid container justifyContent="center">
                <TypoGraphyTitle sx={{ margin: 0 }}></TypoGraphyTitle>
              </Grid>
              <Grid
                className="gap-11 lg:gap-32 w-full "
                container
                justifyContent="center"
                alignItems={"center"}
              >
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/phone.png" alt="" />
                </a>
                <a
                  href="https://line.me/th/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/line.png" alt="" />
                </a>
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/fackbook.png" alt="" />
                </a>
                <a
                  href="http://www.YouTube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20 " src="/youtube.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/instagram.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/googlemap.png" alt="" />
                </a>
              </Grid>
            </Grid>
          </Grid> */}
        {/* </Containervontact> */}

        {/* <Grid
          className="mt-16 w-full lg:w-full px-16 pb-5 "
          md={"auto"}
          justifyContent="center"
          sx={{
            height: { xs: "200px", sm: "300px", md: "500px", lg: "600px" },
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15494.706465268226!2d100.62448471784592!3d13.858439926050213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d62c8483d2e9f%3A0xef86cec4b9ef36c6!2z4LiV4Lil4Liy4LiU4LiW4LiZ4Lit4Lih4Lih4Li04LiV4Lij!5e0!3m2!1sth!2sth!4v1708631872996!5m2!1sth!2sth"
            width="100%"
            height="100%"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid> */}
      </Grid>
      {/* ------------------------------------------------------------------------------------- */}
    </Grid>
  );
};

export default Home;
