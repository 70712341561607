import React, { useState } from "react";

export default function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  textHead,
  textBody
}: {
  isOpen: any;
  onClose: any;
  onConfirm: any;
  textHead:any,
  textBody:any,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <h2 className="text-xl mb-4">{textHead}</h2>
        <p className="mb-6">{textBody}</p>
        <div className="flex justify-end">
        <button
            className="ml-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-150"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-150"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
