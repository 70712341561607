import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
} from "@mui/material";

export default function OrdersTable({
  orders,
  findOrder,
}: {
  orders: any;
  findOrder: any;
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paymentStatus, setPaymentStatus] = useState("ทั้งหมด");
  const [shippingStatus, setShippingStatus] = useState("ทั้งหมด");

  const handlePaymentChange = (e: any) => {
    setPaymentStatus(e.target.value);
  };

  const handleShippingChange = (e: any) => {
    setShippingStatus(e.target.value);
  };

  const handleClear = () => {
    setPaymentStatus("ทั้งหมด");
    setShippingStatus("ทั้งหมด");
  };

  const filteredOrders = orders.filter((order: any) => {
    const matchPayment =
      paymentStatus === "ทั้งหมด" ||
      (paymentStatus === "จ่ายเงินแล้ว" && order.status_payment === 1) ||
      (paymentStatus === "ยังไม่จ่าย" && order.status_payment === 0);

    const matchShipping =
      shippingStatus === "ทั้งหมด" ||
      (shippingStatus === "จัดส่งแล้ว" && order.status_shipping === 1) ||
      (shippingStatus === "ยังไม่จัดส่ง" && order.status_shipping === 0);

    return matchPayment && matchShipping;
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedRows = filteredOrders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box>
      {/* Filters Section */}
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        p={3}
        sx={{ bgcolor: "#f5f5f5", borderRadius: "8px", mb: 2 }}
      >
        <Select
          value={paymentStatus}
          onChange={handlePaymentChange}
          displayEmpty
          variant="outlined"
          sx={{ width: "180px" }}
        >
          <MenuItem value="ทั้งหมด">การจ่ายเงิน: ทั้งหมด</MenuItem>
          <MenuItem value="จ่ายเงินแล้ว">จ่ายเงินแล้ว</MenuItem>
          <MenuItem value="ยังไม่จ่าย">ยังไม่จ่าย</MenuItem>
        </Select>

        <Select
          value={shippingStatus}
          onChange={handleShippingChange}
          displayEmpty
          variant="outlined"
          sx={{ width: "180px" }}
        >
          <MenuItem value="ทั้งหมด">การจัดส่ง: ทั้งหมด</MenuItem>
          <MenuItem value="จัดส่งแล้ว">จัดส่งแล้ว</MenuItem>
          <MenuItem value="ยังไม่จัดส่ง">ยังไม่จัดส่ง</MenuItem>
        </Select>

        <Button variant="outlined" color="error" onClick={handleClear}>
          Clear
        </Button>
      </Box>

      {/* Orders Table */}
      <TableContainer component={Paper} elevation={3}>
        <Table sx={{ minWidth: 850 }}>
          <TableHead sx={{ bgcolor: "#eceff1" }}>
            <TableRow>
              <TableCell width={100} sx={{ fontWeight: "bold" }}>
                ID
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>ชื่อผู้สั่งซื้อ</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                ที่อยู่สำหรับจัดส่ง
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>สร้างเมื่อ</TableCell>
              <TableCell sx={{ fontWeight: "bold", width: 120 }}>
                การจ่ายเงิน
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: 120 }}>
                การจัดส่ง
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: 140 }}>
                รายละเอียด
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((order: any) => (
              <TableRow key={order.order_id}>
                <TableCell>{order.order_id}</TableCell>
                <TableCell>
                  {order.firstname} {order.lastname}
                </TableCell>
                <TableCell>{order.address}</TableCell>
                <TableCell>
                  {new Date(order.createdAt).toLocaleString()}
                </TableCell>
                <TableCell
                  sx={{
                    color: order.status_payment ? "#25a920" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {order.status_payment ? "จ่ายเงินแล้ว" : "ยังไม่จ่าย"}
                </TableCell>
                <TableCell
                  sx={{
                    color: order.status_shipping ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {order.status_shipping ? "จัดส่งแล้ว" : "รอจัดส่ง"}
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    onClick={() => findOrder(order)}
                    variant="contained"
                    autoFocus
                  >
                    ดูรายละเอียด
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
