import {
  BorderAllRounded,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import {
  Grid,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Box,
  Modal,
} from "@mui/material";
import Cookies from "universal-cookie";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaBuffer, FaImage, FaPlusSquare } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Category = () => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [nameCS, setNameCS] = useState("");
  const [ID, setID] = useState(0);
  const [SID, setSID] = useState(0);
  const [image, setImage] = useState<any>(null);

  const [categoriesRef, setCategoriesRef] = useState<any>([]);
  const [subCategoriesRef, setSubCategoriesRef] = useState<any>([]);
  const nameRef = useRef<any>(null);

  const columns: GridColDef[] = [
    { field: "name", headerName: "ชื่อหมวดหมู่", flex: 1 },
    { field: "count", headerName: "รายการสินค้าในหมวดหมู่", flex: 1 },
    {
      field: "action",
      headerName: "คำสั่ง",
      flex: 1,
      renderCell: (r) => {
        return (
          <>
            <div className="flex gap-[3%] justify-evenly">
              <Button
                variant={"contained"}
                color="error"
                size={"small"}
                onClick={() => {
                  deleteCategories(r.row.id);
                }}
              >
                <DeleteOutline fontSize="small" />
              </Button>
              <Button
                variant={"contained"}
                color="info"
                size={"small"}
                onClick={() => {
                  setID(r.row.id);
                  setModal(true);
                }}
              >
                <FaImage fontSize="small" />
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                size={"small"}
                onClick={() => {
                  console.log(subCategoriesRef);

                  setID(r.row.id);
                  setModal2(true);
                }}
              >
                <FaBuffer fontSize="small" />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        const data = response.data;

        setCategoriesRef(data);
      });
  };

  const getSubCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/getSub`)
      .then((response) => {
        const data = response.data.data;

        setSubCategoriesRef(data);
      });
  };

  const addImages = async () => {
    setModal(false);
    if (image != null && image != undefined) {
      const formData = new FormData();
      formData.append("file", image);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          if (response.data.message == "OK") {
            await axios
              .post(
                `${process.env.REACT_APP_API_URL}/categories/addPath`,
                {
                  id: ID,
                  path: response.data.path,
                },
                {
                  headers: {
                    token: cookies.get("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.message == "OK") {
                  Swal.fire({
                    title: "เพิ่มรูปสำเร็จ",
                    icon: "success",
                  }).then(() => {
                    window.location.reload();
                  });
                }
              });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูปภาพ",
        icon: "error",
      });
    }
  };

  const addCategories = async () => {
    const name = nameRef.current.value;

    if (name != null && name !== "" && name !== undefined) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/categories/add`,
          {
            name: name,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "OK") {
            Swal.fire({
              title: "เพิ่มหมวดหมู่สินค้าเรียบร้อยแล้ว",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `เพิ่มหมวดหมู่สินค้าไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      const notify = (msg: any, options: any) => toast(msg, { ...options });
      notify("Please input categories name", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  const addSubCategories = async () => {
    setModal2(false);
    if (nameCS != null && nameCS !== "" && nameCS !== undefined) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/categories/addSub`,
          {
            id: ID,
            name: nameCS,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "OK") {
            Swal.fire({
              title: "เพิ่มหมวดหมู่ย่อยเรียบร้อยแล้ว",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `เพิ่มหมวดหมู่ย่อยไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      const notify = (msg: any, options: any) => toast(msg, { ...options });
      notify("Please input categories name", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  const deleteCategories = async (id: any) => {
    if (id != null && id !== "" && id !== undefined) {
      Swal.fire({
        title: "ยืนยันการลบหมวดหมู่สินค้านี้ ?",
        text: "แน่ใจที่จะลบหมวดหมู่สินค้านี้ใช่หรือไม่ หากใช่หมวดหมู่สินค้านี้จะถูกลบออกไป",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ใช่, ลบเลย!",
        cancelButtonText: "ไม่, ยกเลิก!",
      }).then((status) => {
        if (status.isConfirmed) {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/categories/delete`,
              {
                id: id,
              },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.message === "OK") {
                Swal.fire({
                  title: "ลบหมวดหมู่สินค้าเรียบร้อยแล้ว",
                  icon: "success",
                }).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  text: `ลบหมวดหมู่สินค้าไม่สำเร็จ (${response.data.message})`,
                  icon: "error",
                });
              }
            });
        }
      });
    } else {
      const notify = (msg: any, options: any) => toast(msg, { ...options });
      notify("Choose your categories for delete", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  const deleteSubCategories = async (id: any) => {
    setModal2(false);
    Swal.fire({
      title: "ยืนยันการลบหมวดหมู่นี้ ?",
      text: "แน่ใจที่จะลบหมวดหมู่นี้ใช่หรือไม่ หากใช่หมวดหมู่นี้จะถูกลบออกไป",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
    }).then((status) => {
      if (status.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/categories/deleteSub`,
            {
              id: id,
            },
            {
              headers: {
                token: cookies.get("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.message === "OK") {
              Swal.fire({
                title: "ลบหมวดหมู่เรียบร้อยแล้ว",
                icon: "success",
              }).then(() => {
                window.location.reload();
              });
            } else {
              Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: `ลบหมวดหมู่ไม่สำเร็จ (${response.data.message})`,
                icon: "error",
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    if (
      cookies.get("token") == null ||
      cookies.get("token") === "" ||
      cookies.get("token") === undefined
    ) {
      return navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/token/check`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        if (response.data.isAdmin !== 1) {
          return navigate("/");
        }
      });
    getCategories();
    getSubCategories();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Toaster />
          <FormControl fullWidth>
            <FormLabel>ชื่อหมวดหมู่</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อหมวดหมู่"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={nameRef}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel>&nbsp;</FormLabel>
          <Button
            onClick={() => {
              addCategories();
            }}
            fullWidth
            variant={"contained"}
            size={"large"}
          >
            + เพิ่มหมวดหมู่
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{ width: { xs: 200, sm: 200, md: "100%" } }}
            style={{ overflow: "auto" }}
          >
            <DataGrid
              sx={{ width: { xs: 400, sm: 400, md: "100%" } }}
              // getRowId={(row: GridRowModel) => row.id}
              // getRowHeight={() => 'auto'}
              rows={categoriesRef}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowSelection={false}
            />
          </Box>
          <Modal
            open={modal}
            onClose={() => {
              setModal(false);
              setID(0);
              setImage(null);
            }}
          >
            <Box sx={{ ...style, width: { md: "30%", xs: "95%" } }}>
              <img
                className="w-full"
                src={
                  categoriesRef.filter((f: any) => ID === f.id)[0] &&
                  categoriesRef.filter((f: any) => ID === f.id)[0].image_path
                    ? categoriesRef
                        .filter((f: any) => ID === f.id)[0]
                        .image_path.replace(
                          "{api_path}",
                          process.env.REACT_APP_API_URL
                        )
                    : "/404-not-found.png"
                }
                alt=""
              />
              <input
                className="py-2"
                type="file"
                onChange={(e: any) => setImage(e.target.files[0])}
              />
              <Button
                onClick={addImages}
                fullWidth
                variant={"contained"}
                size={"large"}
              >
                แก้ไขภาพ
              </Button>
            </Box>
          </Modal>
          <Modal
            open={modal2}
            onClose={() => {
              setID(0);
              setNameCS("");
              setModal2(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, width: { md: "30%", xs: "95%" } }}>
              <p className="font-bold text-lg">รายการหมวดหมู่</p>
              <div className="border rounded-md w-full h-60 overflow-y-auto">
                {subCategoriesRef.filter((f: any) => f.category_id === ID) &&
                  subCategoriesRef
                    .filter((f: any) => f.category_id === ID)
                    .map((m: any) => (
                      <>
                        <div className="flex justify-between border p-2">
                          <div>{m.name}</div>
                          <Button
                            variant={"contained"}
                            color="error"
                            size={"small"}
                            onClick={() => {
                              deleteSubCategories(m.id);
                            }}
                          >
                            <DeleteOutline fontSize="small" />
                          </Button>
                        </div>
                      </>
                    ))}
              </div>
              <div className="flex mt-4">
                <input
                  type="text"
                  placeholder="เพิ่มหมวดย่อย"
                  className="p-2 w-4/5 border rounded-sm"
                  value={nameCS}
                  onChange={(e: any) => setNameCS(e.target.value)}
                />
                <Button
                  onClick={addSubCategories}
                  variant={"contained"}
                  size={"small"}
                >
                  <FaPlusSquare fontSize="large" />
                </Button>
              </div>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

export default Category;
