import Navbar from "./component/navbar/Navbar";
import { Box } from "@mui/material";
import { Outlet } from "react-router";
import Footer from "./component/footer/Footer";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Loading from "./component/Loading";

export default function App() {
  const cookies = new Cookies(null, { path: "/" });
  // USE STATE
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [carts, setCarts] = useState<any>([]);
  const [wishlist, setWishlist] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [BannerYT, setBannerYT] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  // FUNCTION
  const reloadCarts = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/carts/get`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        const sortCartsByProductCreated = response.data.carts.sort(function (
          a: any,
          b: any
        ) {
          var d1: any = new Date(a.productCreatedAt);
          var d2: any = new Date(b.productCreatedAt);
          return d1 - d2;
        });
        const sortCartsByPriceCreated = sortCartsByProductCreated.sort(
          function (a: any, b: any) {
            var d1: any = new Date(a.priceCreatedAt);
            var d2: any = new Date(b.priceCreatedAt);
            return d1 - d2;
          }
        );

        setCarts(sortCartsByPriceCreated);
      });
  };

  const reloadWishlist = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/wishlist/all`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        if (response.data.message == "OK") {
          setWishlist(response.data.wishlist);
        }
      });
  };

  const reloadProducts = async (array: any = undefined) => {
    if (array === undefined) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/products/all`)
        .then((response) => {
          setAllProducts(response.data.product);
        });
    } else if (array.length == 0) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/products/all`)
        .then((response) => {
          setAllProducts(response.data.product);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/products/inCategory`, {
          array: array,
        })
        .then((response) => {
          setAllProducts(response.data);
        });
    }
  };

  const reloadCategories = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        setCategories(response.data);
      });
  };

  const reloadBannerAndYT = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/banner/getBannerAndYT`)
      .then((response) => {
        setBannerYT(response.data);
      });
  };

  // USE EFFECT
  // Set is Logged
  useEffect(() => {
    const load = async () => {
      await reloadProducts();
      await reloadCategories();
      await reloadBannerAndYT();

      const token = cookies.get("token");
      if (token == undefined) {
        setLoaded(true);
        return;
      }

      await axios
        .get(`${process.env.REACT_APP_API_URL}/token/check`, {
          headers: {
            token: cookies.get("token") || null,
          },
        })
        .then((response) => {
          if (response.data.message === "Token is vailded") {
            setIsLogged(true);
            reloadCarts();
            reloadWishlist();
            response.data.isAdmin == 1 ? setIsAdmin(true) : setIsAdmin(false);
          } else {
            setIsLogged(false);
            cookies.remove("token");
          }
        });

      setLoaded(true);
    };

    load();
  }, []);

  return (
    <Box>
      <Navbar isLogged={isLogged} carts={carts} categories={categories} />
      <main>
        {loaded ? (
          <Outlet
            context={[
              {
                isLogged: isLogged,
                carts: carts,
                wishlist: wishlist,
                isAdmin: isAdmin,
                allProducts: allProducts,
                categories: categories,
                bannerYT: BannerYT,
                reloadCarts: reloadCarts,
              },
            ]}
          />
        ) : (
          <Loading open={loaded} />
        )}
      </main>
      <Footer categories={categories} />
    </Box>
  );
}
