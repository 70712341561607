import { Button, ButtonGroup } from "@mui/material";
import { useRef, useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies(null, { path: '/' });

interface Props {
    size?: string,
    getSubTotal?: any,
    data?: any,
    enableRemove?: any
}

export default function ButtonCal(props: Props) {

    const [size] = useState<string | any>(props.size || 'large')

    const [quantity, setQuantity] = useState(props.data.quantity || 1);
    const [priceId, setPriceId] = useState(props.data.product_price_id);
    const [enableRemove, setEnableRemove] = useState(true);

    useEffect(() => {
        if(props.enableRemove != undefined)
        {
            setEnableRemove(props.enableRemove)
        }

        if(props.data != undefined)
        {
            if(props.data.quantity != undefined)
            {
                setQuantity(props.data.quantity)
            }

            if(props.data.product_price_id != undefined)
            {
                setPriceId(props.data.product_price_id)
            }
        }
    }, [props.data])

    return (
        <ButtonGroup
            sx={{
                border: '1px solid #ccc',
                borderRadius: 17,

            }}
            size={size}
            variant="contained"
            aria-label="Button group with a nested menu"
        >
            <Button
                sx={{
                    border: '0px !important',
                    background: 'white',
                    color: 'black',
                    borderTopLeftRadius: 23,
                    borderBottomLeftRadius: 23,
                    '&:hover': {
                        color: "white",
                    },
                }}
                size={size}
                onClick={() => {
                    if ((quantity - 1) <= 0)
                    {
                        if(enableRemove)
                        {
                            Swal.fire({
                                title: "ยืนยันการลบสินค้า ?",
                                text: "แน่ใจที่จะลบสินค้าใช่หรือไม่ หากใช่สินค้านี้จะถูกลบออกไปจากตะกร้า",
                                icon: "error",
                                showCancelButton: true,
                                confirmButtonText: "ใช่, ลบเลย!",
                                cancelButtonText: "ไม่, ยกเลิก!",
                            }).then((status) => {
                                if (status.isConfirmed) {
                                    axios.post(`${process.env.REACT_APP_API_URL}/carts/delete`, {
                                        price_id: priceId
                                    }, {
                                        headers: {
                                            token: cookies.get('token')
                                        }
                                    }).then((response:any) => {
                                        if(response)
                                        {
                                            window.location.reload();
                                        }
                                    })
                                }
                                else
                                {
                                    setQuantity(1);
                                }
                            })
                        }
                        else
                        {
                            setQuantity(1);
                            props.getSubTotal(1, props.data);
                        }
                    } else {
                        setQuantity(quantity - 1)
                        props.getSubTotal(quantity - 1, props.data);
                    }

                }}>-</Button>
            <Button sx={{

                border: '0px !important',
                background: 'white',
                color: 'black'
            }}
                size={size}
                onClick={() => { }}>{quantity}
            </Button>

            <Button
                sx={{
                    border: '0px !important',
                    background: 'white',
                    color: 'black',
                    borderTopRightRadius: 23,
                    borderBottomRightRadius: 23,
                    '&:hover': {
                        color: "white",
                    },
                }}
                size={size}
                onClick={() => {
                    if(quantity + 1 > props.data.stock) {
                       
                        Swal.fire({
                            title: "ไม่สารถเพิ่มจำนวนสินค้า",
                            text: "จำนวนสินค้าใน Stock ไม่เพียงพอ",
                            icon: "warning",
                        })
                    } else {
                        setQuantity(quantity + 1)
                        props.getSubTotal(quantity + 1, props.data);
                    }

                }}
            >
                +
            </Button>
        </ButtonGroup>
    )
}