import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import hero from "../image/Home/imghero.png";
import Logo from "../image/about/logo.png";
import no1level1 from "../image/Pic หน้ากาก แบบยกลัง/level1.jpg";
import AboutUs from "../component/about/AboutUs";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";

const CardCustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const Containervontact = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textIndent: "12px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
  },
}));
const TypoGraphyHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "0 auto",
  fontWeight: 500,
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const CardCustom = styled(Box)(({ theme }) => ({
  fontSize: "36px",
  borderRadius: "16px",
  width: "250px",
  textAlign: "center",
  margin: "0 auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
}));

const BoxCustomBand = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function About() {
  const Theme = useTheme();

  return (
    // <Grid container flexDirection="column" gap="24px">
    //   <Container>
    //       <Grid container margin="0 auto" width="80%" mt={3}>
    //           <img src={hero} alt='howto' width="100%" />
    //       </Grid>
    //   </Container>
    //   <Grid bgcolor="#EAFFF5" py={3} gap={3}>
    //     <Container>
    //       <Grid container direction={{ xs:"column-reverse", md: "row" }} >
    //         <Grid xs={12} md={5}>
    //           <img src={no1level1} style={{ width: '100%', borderTopLeftRadius: '30%', boxShadow: "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)" }} />
    //           <Grid sx={{ display: { xs: 'block', md: 'none' } }}>
    //             <ul>
    //               <li>Lorem ipsum dolor sit amet</li>
    //               <li>Lorem ipsum dolor sit amet</li>
    //               <li>Lorem ipsum dolor sit amet</li>
    //             </ul>
    //           </Grid>
    //         </Grid>
    //         <Grid xs={12} md={6}>
    //           <Container>
    //             <TypoGraphyTitle>About Us</TypoGraphyTitle>
    //             <TypoGraphyHead margin={0}>
    //               MED WHOLESALE
    //             </TypoGraphyHead>
    //             <TypoGraphyTitle>
    //               Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet tortor vel. Praesent lacinia mi amet sed vitae. Venenatis pulvinar varius a semper nullam viverra ultricies aliquam. Nisl ultricies et nisi nisl cras scelerisque phasellus.
    //             </TypoGraphyTitle>
    //             <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
    //               <ul>
    //                 <li>Lorem ipsum dolor sit amet</li>
    //                 <li>Lorem ipsum dolor sit amet</li>
    //                 <li>Lorem ipsum dolor sit amet</li>
    //               </ul>
    //             </Grid>
    //           </Container>
    //         </Grid>
    //       </Grid>
    //       <Grid>
    //         <TypoGraphyHead margin={0}>MED WHOLESALE</TypoGraphyHead>
    //         <TypoGraphyTitle pt={2}>
    //           Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet tortor vel. Praesent lacinia mi amet sed vitae. Venenatis pulvinar varius a semper nullam viverra ultricies aliquam. Nisl ultricies et nisi nisl cras scelerisque phasellus.
    //         </TypoGraphyTitle>

    //         <TypoGraphyTitle pt={3}>
    //           Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet tortor vel. Praesent lacinia mi amet sed vitae. Venenatis pulvinar varius a semper nullam viverra ultricies aliquam. Nisl ultricies et nisi nisl cras scelerisque phasellus.
    //         </TypoGraphyTitle>
    //       </Grid>
    //       <Grid container justifyContent="end">
    //         <Box sx={{ width: { xs: "150px", md: "200px" } }}>
    //           <img src={Logo} width="100%" />
    //         </Box>
    //       </Grid>
    //     </Container>
    //   </Grid>
    // </Grid>
    <>
      <div className="py-[5%]">

      <div className="-mb-20 md:mb-1">
      <AboutUs />
      </div>
        <Grid container flexDirection="column" sx={{ px: "4%", pt:"2%" }} className=""  >
          {/* <div className="justify-center flex mb-5">
          <hr className="w-[308px] h-1 bg-[#134C30] text-green-700 " />
        </div> */}
          {/* <Containervontact> */}
            {/* <Grid container>
          <CardCustomHead>CONTACT US</CardCustomHead>
          </Grid>
          <div className='border border-black relative bottom-16 opacity-5'>

          </div> */}
            <ContactUsNewVer />
            {/* <Grid
            className="flex-col"
            container
            flexDirection={{ xs: "row", md: "column" }}
            justifyContent="center"
            gap={{ xs: 1, sm: 3 }}
          >
            <Grid
              container
              xs={5}
              md={12}
              flexDirection={{ xs: "column", md: "row", lg: "row" }}
              justifyContent="center"
              gap={{ xs: 1 }}
              alignItems="center"
            >
              <Grid container justifyContent="center">
                <TypoGraphyTitle sx={{ margin: 0 }}></TypoGraphyTitle>
              </Grid>
              <Grid
                className="gap-11 lg:gap-32 w-full "
                container
                justifyContent="center"
                alignItems={"center"}
              >
                <a
                  href="tel:083-448-8442"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/phone.png" alt="" />
                </a>
                <a
                  href="https://line.me/th/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/line.png" alt="" />
                </a>
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/fackbook.png" alt="" />
                </a>
                <a
                  href="http://www.YouTube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20 " src="/youtube.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/instagram.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/googlemap.png" alt="" />
                </a>
              </Grid>
            </Grid>
          </Grid> */}
          {/* </Containervontact> */}
          {/* <Grid
          className="mt-16 w-full lg:w-full px-16 pb-5 "
          md={"auto"}
          justifyContent="center"
          sx={{
            height: { xs: "200px", sm: "300px", md: "500px", lg: "600px" },
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15494.706465268226!2d100.62448471784592!3d13.858439926050213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d62c8483d2e9f%3A0xef86cec4b9ef36c6!2z4LiV4Lil4Liy4LiU4LiW4LiZ4Lit4Lih4Lih4Li04LiV4Lij!5e0!3m2!1sth!2sth!4v1708631872996!5m2!1sth!2sth"
            width="100%"
            height="100%"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid> */}
        </Grid>
      </div>
    </>
  );
}

export default About;
