import { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";

import ReactPlayer from "react-player";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Rating,
  Typography,
  styled,
} from "@mui/material";
// import hero from "../image/Home/imghero.png";
import hero from "../image/Home/hero2.png";

import TagDiscont from "../component/TagDiscont";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import mask from "../image/หน้ากาก LV.1/mask01.jpg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import no1level1 from "../image/Pic หน้ากาก แบบยกลัง/level1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import review from "../assets/images/review.jpg";
import { axiosService } from "../services/axios";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";
import HomeFilterByCategories from "../component/Product/HomeFilterbyCategories";
import meskset from "../image/meskset.png";
import maskwhite from "../image/หน้ากาก LV.1/maskwhite.jpg";
import { LocalMall, ShoppingBagOutlined } from "@mui/icons-material";
import TagDisconthome from "../component/TagDisconthome";
import mask2 from "../image/maskwhite.jpg";
import axios from "axios";
import gloveg from "../image/gloveg.png";

import "swiper/swiper-bundle.css";
import "swiper/css/scrollbar";
// import { Autoplay } from 'swiper/modules';
// import { Container } from '@mui/material';

const Containervontact = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));
const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textIndent: "12px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));
const CardCustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const CardCustomHeadReview = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontWeight: "500",
  margin: "0 auto",
  padding: "12px",
  width: "560px",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.down("sm")]: {
    width: "280px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    width: "350px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
    width: "560px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const TypoGraphyHead = styled(Typography)(({ theme }) => ({
  color: "black",
  margin: "0 auto",
  fontWeight: 300,
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "34px",
  },
}));

const CardCustomProd = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  fontSize: "24px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "70%",
  },
}));

const CardCustomProdpro = styled(Box)(({ theme }) => ({
  padding: "5px",
  fontSize: "22px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "90%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "90%",
  },
}));

const CardCustomProdproRelated = styled(Box)(({ theme }) => ({
  padding: "5px",
  fontSize: "22px",
  borderRadius: "16px",
  flexShrink: "0",
  textAlign: "center",
  margin: "0 auto",
  userSelect: "none",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "90%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "90%",
  },
}));

const Img = styled("img")(({ theme }) => ({}));
const CustomHead = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
    width: "75%",
    padding: 10,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    width: "350px",
    padding: 10,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "25px",
    width: "400px",
    padding: 15,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "25px",
    width: "500px",
    padding: 15,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "25px",
    width: "500px",
    padding: 15,
  },
  fontWeight: 500,
  margin: "0 auto",
}));
const Card = styled(Grid)(({ theme }) => ({
  width: "100%",
  maxWidth: "300px",
  borderRadius: "15px",
  justifyContent: "center",
  border: "1px solid #DEDEDE",
  flexDirection: "column",
  rowGap: "14px",
  margin: "0 auto",
}));

export default function Product() {
  const params = useParams();
  const { id } = useParams();
  // const [products, setProducts] = useState<any[]>([]);
  const [products, setProducts] = useState<Record<string, any[]>>({});
  const [productsData1, setProductsData1] = useState<Record<string, any[]>>({});
  const [productsData2, setProductsData2] = useState<Record<string, any[]>>({});

  const Navigate = useNavigate();
  const prod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const discont = "20";
  const navigate = useNavigate();

  const context: any = useOutletContext();
  const [categories, setCategories] = useState<any>([]);
  const [listProducts, setListProducts] = useState<any>([]);
  const [allProducts, setAllproducts] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<string>("");

  // useEffect(() => {
  //   fetchMask();
  //   setAllproducts(context[0].allProducts);
  //   setCategories(context[0].categories);

  //   const findIndex = context[0].categories.findIndex((element: any) => {
  //     return element.id == params.id;
  //   });

  //   if (findIndex !== -1) {
  //     setCategoryName(context[0].categories[findIndex].name);
  //   }
  // }, [params]);
  const reviewloop = [1, 2, 3];

  //   useEffect(() => {
  //     const fetchProductsByCategory = async () => {
  //         try {
  //             const response = await axios.get(
  //                 `https://api.medwholesale.co/products/filter/${id}`
  //             );
  //             const data = response.data.data;

  //             let combinedProducts: Record<string, any[]> = {};

  //             const categoryNames = Object.keys(data);
  //             if (categoryNames.length > 0) {
  //                 setCategoryName(categoryNames.join(" & "));
  //             }

  //             categoryNames.forEach((category) => {
  //                 combinedProducts[category] = data[category];
  //             });

  //             setProducts(combinedProducts);
  //         } catch (error) {
  //             console.error("Error fetching products by category:", error);
  //         }
  //     };

  //     fetchProductsByCategory();
  // }, [id]);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/filter/${id}`
        );
        const data1 = response.data.data;
        const data2 = response.data.data2;

        let combinedProducts1: Record<string, any[]> = {};
        let combinedProducts2: Record<string, any[]> = {};

        const categoryNames1 = Object.keys(data1);
        if (categoryNames1.length > 0) {
          setCategoryName(categoryNames1.join(" & "));
        }

        categoryNames1.forEach((category) => {
          combinedProducts1[category] = data1[category];
        });

        if (data2) {
          const categoryNames2 = Object.keys(data2);
          categoryNames2.forEach((category) => {
            combinedProducts2[category] = data2[category];
          });
        }

        setProductsData1(combinedProducts1);
        setProductsData2(combinedProducts2);
      } catch (error) {
        console.error("Error fetching products by category:", error);
      }
    };

    fetchProductsByCategory();
  }, [id]);

  return (
    <Grid container flexDirection="column" gap="24px">
      <Grid container sx={{ px: "3%", py: "1%" }}>
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true}
          spaceBetween={30}
          slidesPerView={1}
        >
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover  "
              src={context[0].bannerYT.banner.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover  "
              src={context[0].bannerYT.banner2.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover  "
              src={context[0].bannerYT.banner3.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover  "
              src={context[0].bannerYT.banner4.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="pt-5 md:h-[350px] lg:h-[450px] xl:h-[580px] w-full object-cover  "
              src={context[0].bannerYT.banner5.replace(
                "{api_path}",
                process.env.REACT_APP_API_URL
              )}
              alt="hero1"
            />
          </SwiperSlide>
        </Swiper>
      </Grid>
      <Grid container flexDirection="column" gap="16px">
        <Grid
          container
          gap="16px"
          maxWidth="2000px"
          sx={{
            margin: "0 auto",
          }}
        ></Grid>

        <Grid className="flex-col lg:px-20 px-1 w-full justify-start lg:justify-center">
          {Object.keys(productsData1).map((categoryName, categoryIndex) => (
            <div key={categoryIndex} className="md:mb-20 mb-10">
              <Grid className="px-2 md:px-9 justify-start mb-10">
                <TypoGraphyHead>{categoryName}</TypoGraphyHead>
              </Grid>
              <div className="px-2">
                <Grid className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                  {productsData1[categoryName].map((item: any, index: any) => (
                    <Grid item key={index}>
                      <CardCustomProdpro>
                        <div className="px-2">
                          <Box sx={{ position: "relative" }}>
                            <img
                              src={
                                item.image?.replace(
                                  "{api_path}",
                                  process.env.REACT_APP_API_URL
                                ) || "/404-not-found.png"
                              }
                              className="w-full h-[150px] md:h-[270px] object-contain"
                              alt={item.name}
                            />
                            <TagDisconthome discont={`20`} />
                          </Box>
                          <div className="text-center">
                            <div className="text-left mt-2">
                              <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                                <b>{item.name}</b>
                              </p>
                            </div>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="pb-4 pt-2"
                            >
                              <p className="text-sm font-normal md:text-xl text-left text-[#0A3A22]">
                                ฿{" "}
                                {item.price != 0
                                  ? item.price
                                  : item.price_s != 0
                                  ? item.price_s
                                  : item.price_m != 0
                                  ? item.price_m
                                  : item.price_l != 0
                                  ? item.price_l
                                  : item.price_xl != 0
                                  ? item.price_xl
                                  : "0"}
                              </p>
                              <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                                ขายแล้ว (1 ชิ้น)
                              </span>
                            </Grid>
                            <Button
                              className="items-center"
                              sx={{ boxShadow: 2, borderRadius: 5 }}
                              onClick={() => {
                                navigate(`/product_detail/${item.id}`);
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <div className="px-2 items-center flex text-xs md:text-sm">
                                <ShoppingBagOutlined
                                  sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    mx: "2px",
                                  }}
                                />
                                หยิบใส่ตะกร้า
                              </div>
                            </Button>
                          </div>
                        </div>
                      </CardCustomProdpro>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          ))}
        </Grid>

        <Grid container sx={{ px: "3%", py: "1%" }}>
          <img
            className="pt-5 md:h-[300px] lg:h-[350px] xl:h-[500px] w-full object-cover"
            src={hero}
            alt="hero1"
          />
        </Grid>

        <br />

        {/* -----------------------------------MaskSet ------------------------------------------------*/}

        <Grid className="flex-col lg:px-20 px-1 w-full justify-start lg:justify-center">
          {Object.keys(productsData2).map((categoryName, categoryIndex) => (
            <div key={categoryIndex} className="md:mb-20 mb-10">
              <Grid className="px-2 md:px-9 justify-start mb-10">
                <TypoGraphyHead>{categoryName}</TypoGraphyHead>
              </Grid>
              <div className="px-2">
                <Grid
                  spacing={2}
                  className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
                >
                  {productsData2[categoryName].map((item: any, index: any) => (
                    <Grid item key={index}>
                      <CardCustomProdpro>
                        <div className="px-2">
                          <Box sx={{ position: "relative" }}>
                            <img
                              src={
                                item.image?.replace(
                                  "{api_path}",
                                  process.env.REACT_APP_API_URL
                                ) || "/404-not-found.png"
                              }
                              className="w-full h-[150px] md:h-[270px] object-contain"
                              alt={item.name}
                            />
                            <TagDisconthome discont={`20`} />
                          </Box>
                          <div className="text-center">
                            <div className="text-left mt-2">
                              <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                                <b>{item.name}</b>
                              </p>
                              {/* <p className="text-[50%] md:text-[70%] font-light">
                            <b>
                              {item.description
                                ?.split(" ")
                                .slice(0, 5)
                                .join(" ")}
                              {item.description?.split(" ").length > 5 &&
                                " ..."}
                            </b>
                          </p> */}
                            </div>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="pb-4 pt-2"
                            >
                              <p className="text-sm font-normal md:text-xl text-left text-[#0A3A22]">
                                ฿ {item.price}
                              </p>
                              <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                                ขายแล้ว (1 ชิ้น)
                              </span>
                            </Grid>
                            <Button
                              className="items-center"
                              sx={{ boxShadow: 2, borderRadius: 5 }}
                              onClick={() => {
                                navigate(`/product_detail/${item.id}`);
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <div className="px-2 items-center flex text-xs md:text-sm">
                                <ShoppingBagOutlined
                                  sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    mx: "2px",
                                  }}
                                />
                                หยิบใส่ตะกร้า
                              </div>
                            </Button>
                          </div>
                        </div>
                      </CardCustomProdpro>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          ))}
        </Grid>

        {/* <Grid className="mt-11">
          <CustomHead>รีวิวจากลูกค้า ขอบคุณที่วางใจเสมอมา</CustomHead>
        </Grid> */}

        <Divider className="px-[5%] py-10">
          <CardCustomHeadReview className="opacity-100">
            รีวิวจากลูกค้า ขอบคุณที่วางใจเสมอมา
          </CardCustomHeadReview>
        </Divider>

        <Grid
          container
          justifyContent={"space-evenly"}
          rowGap={"24px"}
          className="mb-14"
        >
          <Swiper
            modules={[Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            loop={true}
            breakpoints={{
              30: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            style={{ width: "100%", maxWidth: "100vw", overflow: "hidden" }}
          >
            {reviewloop.map((id) => (
              <SwiperSlide
                key={id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  style={{
                    border: "none",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <Grid
                    // key={id}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    // sx={
                    //   id > 1
                    //     ? { display: { xs: "none", md: "flex" } }
                    //     : { display: "flex" }
                    // }
                    width={"100%"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    {/* avatar */}
                    <Grid
                      sx={{
                        overflow: "hidden",
                        borderRadius: "50%",
                        width: {
                          xs: "120px",
                          sm: "110px",
                          md: "130px",
                          lg: "150px",
                        },
                        height: {
                          xs: "120px",
                          sm: "110px",
                          md: "130px",
                          lg: "150px",
                        },
                      }}
                    >
                      <img
                        src={review}
                        alt="Review"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    {/* rating */}
                    <Rating
                      name="read-only"
                      value={4}
                      readOnly
                      className="py-3"
                    />
                    <Typography
                      variant="body2"
                      textAlign={"center"}
                      color={"#0A3A22"}
                      fontSize={{ xs: 20, md: 18, lg: 21 }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Tortor amet
                      facilisi mi et sit.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: "16px",
                        textAlign: "center",
                        color: "#0A3A22",
                      }}
                    >
                      Lorem ipsum
                    </Typography>
                  </Grid>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>

        <Box width="100%" padding={{ md: 6 }}>
          <ReactPlayer
            controls
            playIcon={<PlayArrowIcon />}
            url={context[0].bannerYT.youtube}
            width={"100%"}
          />
        </Box>

        {/* <CardCustomHead>สินค้าที่เกี่ยวข้อง</CardCustomHead> */}
        <Grid className="px-2 pt-5 md:px-24 justify-start">
          <TypoGraphyHead>สินค้าที่เกี่ยวข้อง</TypoGraphyHead>
        </Grid>

        <Grid container className=" gap-11 py-10 md:px-[5%] px-[1%]">
          <Grid
            container
            gap="16px"
            maxWidth="1650px"
            sx={{ margin: "0 auto" }}
          >
            <Swiper
              modules={[Autoplay]}
              // loop={true}
              // autoplay={{
              //   delay: 3,
              //   pauseOnMouseEnter: true,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              grabCursor={false}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                600: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
              speed={3000}
            >
              {context[0].allProducts &&
                context[0].allProducts.map((item: any, index: any) => {
                  return (
                    <SwiperSlide key={index}>
                      <CardCustomProdproRelated>
                        <div className="px-3">
                          <Box sx={{ position: "relative" }}>
                            <img
                              src={
                                item?.path?.replace(
                                  "{api_path}",
                                  process.env.REACT_APP_API_URL
                                ) || "/404-not-found.png"
                              }
                              className="w-full h-[150px] md:h-[270px] object-contain"
                              alt={item.name}
                            />
                            <TagDisconthome discont={`20`} />
                          </Box>
                          <div className="text-center">
                            <div className="text-left mt-2">
                              <p className="text-[60%] md:text-[70%] lg:text-[70%] 2xl:text-lg font-light h-12 md:h-16 lg:h-12 leading-none">
                                <b>{item.name}</b>
                              </p>
                              {/* <p className="text-[50%] md:text-[70%] font-light">
                              <b>
                                {item.description
                                  ?.split(" ")
                                  .slice(0, 5)
                                  .join(" ")}
                                {item.description?.split(" ").length > 5 &&
                                  " ..."}
                              </b>
                            </p> */}
                            </div>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="pb-4 pt-2"
                            >
                              <p className="text-sm font-normal md:text-xl text-left text-[#0A3A22]">
                                ฿ {item.price}
                              </p>
                              <span className="text-xs font-light md:text-base relative text-end w-full text-gray-500">
                                ขายแล้ว (1 ชิ้น)
                              </span>
                            </Grid>
                            <Button
                              className="items-center"
                              sx={{ boxShadow: 2, borderRadius: 5 }}
                              onClick={() => {
                                navigate(`/product_detail/${item.id}`);
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <div className="px-2 items-center flex text-xs md:text-sm">
                                <ShoppingBagOutlined
                                  sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    mx: "2px",
                                  }}
                                />
                                หยิบใส่ตะกร้า
                              </div>
                            </Button>
                          </div>
                        </div>
                      </CardCustomProdproRelated>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Grid>
        </Grid>

        <Grid
          container
          flexDirection="column"
          gap="24px"
          className="mb-12"
          sx={{ px: "4%" }}
        >
          <ContactUsNewVer />
        </Grid>
      </Grid>
    </Grid>
  );
}
