import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  Description,
  Details,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { unescape } from "querystring";

const EditContact = ({ param_id }: { param_id: string }) => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const notify = (msg: any, options: any) => toast(msg, { ...options });
  const [show, setShow] = useState(false);

  const [contactRef, setContactRef] = useState<any>([]);
  const [imgRef, setImgRef] = useState<any>("");
  const [nameRef, setNameRef] = useState<any>("");
  const [roleRef, setRoleRef] = useState<any>("");
  const [phoneRef, setPhoneRef] = useState<any>("");
  const [lineRef, setLineRef] = useState<any>("");
  const [cold, setCold] = useState<boolean>(false);

  const [fileImg, setFileImg] = useState<any>(undefined);

  useEffect(() => {
    if (
      cookies.get("token") == null ||
      cookies.get("token") === "" ||
      cookies.get("token") === undefined
    ) {
      return navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/token/check`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        if (response.data.isAdmin !== 1) {
          return navigate("/");
        }
      });
    if (cold === false) {
      getContact();
      setCold(true);
    }
  }, []);

  //   const getCategories = () => {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/categories/all`)
  //       .then((response) => {
  //         const data = response.data;

  //         setCategoriesRef(data);
  //       });
  //   };

  const handleUpload = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }

    setFileImg(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };

  const getContact = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/members/getAllContact/`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        // console.log(data);
        setContactRef(data);
        setImgRef(data[param_id]["img_path"]);
        setLineRef(data[param_id]["line"]);
        setNameRef(data[param_id]["name"]);
        setRoleRef(data[param_id]["role"]);
        setPhoneRef(data[param_id]["phone"]);
      });
  };

  const editProduct = async () => {
    // console.log(userRef);

    if (
      nameRef != null &&
      nameRef !== "" &&
      nameRef !== undefined &&
      roleRef != null &&
      roleRef !== "" &&
      roleRef !== undefined &&
      phoneRef != null &&
      phoneRef !== "" &&
      phoneRef !== undefined &&
      lineRef != null &&
      lineRef !== "" &&
      lineRef !== undefined
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/members/changeContact/`,
          {
            id: param_id,
            name: nameRef,
            role: roleRef,
            line: lineRef,
            phone: phoneRef,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.message === "success") {
            Swal.fire({
              title: "แก้ไขผู้ใช้เรียบร้อยแล้ว",
              icon: "success",
            });
            if (fileImg !== undefined) {
              const formData = new FormData();
              formData.append("file", fileImg);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  // console.log(response);

                  if (response.data.message == "OK") {
                    await axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/members/insertpathimgToContact`,
                        {
                          id: param_id,
                          path_img: response.data.path,
                        },
                        {
                          headers: {
                            token: cookies.get("token"),
                          },
                        }
                      )
                      .then(async (response) => {
                        //   console.log(response);
                      });
                  }
                });
            }
            const timer = setTimeout(() => {
              setShow(true);
              window.location.reload();
            }, 3000);

            return () => clearTimeout(timer);
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขผู้ใช้ไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "กรอกข้อมูลไม่ครบ",
        icon: "error",
      });
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload}
        />
        <label htmlFor="upload-photo">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
      </Stack>
      <br />

      <Grid container spacing={2}>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>ชื่อ</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อ..."
              value={nameRef}
              onChange={(e) => setNameRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>ตำแหน่ง</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ตำแหน่ง..."
              value={roleRef}
              onChange={(e) => setRoleRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>Line</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="Line..."
              value={lineRef}
              onChange={(e) => setLineRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>เบอร์โทรศัพท์</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="เบอร์โทรศัพท์..."
              value={phoneRef}
              onChange={(e) => setPhoneRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => {
                  editProduct();
                }}
              >
                <EditOutlined fontSize={"small"}></EditOutlined>แก้ไขผู้ใช้
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditContact;
