import axios from "axios";
import React, { useEffect, useState } from "react";
import ConfirmModal from "../../component/ConfirmModal";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Paper,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function OrderService({
  item,
  fetchOrders,
}: {
  item: any;
  fetchOrders: any;
}) {
  const cookies = new Cookies(null, { path: "/" });
  const [productItems, setProductItems] = useState<any>([]);
  const [payText, setPayText] = useState(
    item.status_payment ? "จ่ายเงินแล้ว" : "ยังไม่จ่าย"
  );
  const [shiText, setShiText] = useState(
    item.status_shipping ? "จัดส่งแล้ว" : "รอจัดส่ง"
  );

  const navigate = useNavigate();
  const [isModalOpenCancel, setModalOpenCancel] = useState(false);
  const [isModalOpenPayment, setModalOpenPayment] = useState(false);
  const [isModalOpenShipping, setModalOpenShipping] = useState(false);
  const [isModalOpenSlip, setModalOpenSlip] = useState(false);

  const fetchProductOnOrder = async (item: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/products/product-on-orders`,
      item
    );
    setProductItems(response.data);
    console.log(response.data);
  };

  const handleConfirmCancel = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/orders/${item?.order_id}`,
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      );
      await fetchOrders();
      setModalOpenCancel(false);
      alert("Action confirmed!");
    } catch (error) {
      console.error("Error deleting order:", error);
      alert("Failed to delete the order. Please try again.");
    }
  };

  const handleConfirmPayment = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/orders/payment/${item?.order_id}`,
        { newStatus: 1 },
        { headers: { token: cookies.get("token") } }
      );
      setPayText("จ่ายเงินแล้ว");
      await fetchOrders();
      setModalOpenPayment(false);
      alert("Payment confirmed!");
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmShipping = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/orders/shipping/${item?.order_id}`,
        { newStatus: 1 },
        { headers: { token: cookies.get("token") } }
      );
      setShiText("จัดส่งแล้ว");
      await fetchOrders();
      setModalOpenShipping(false);
      alert("Shipping confirmed!");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProductOnOrder(item);
  }, [item]);

  console.log(productItems);
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">ORDER ID : {item.order_id}</Typography>
          <IconButton onClick={() => setModalOpenCancel(true)}>
            <CloseIcon color="error" />
          </IconButton>
        </Grid>

        <Box mt={2}>
          <ShowText
            label="ชื่อ"
            description={`${item.firstname} ${item.lastname}`}
          />
          <ShowText label="เบอร์โทรศัพท์" description={item.phone} />
          <ShowText label="ที่อยู่" description={item.address} />

          <Typography variant="body1" fontWeight="bold">
            การจ่ายเงิน:{" "}
            <Typography
              component="span"
              color={payText === "จ่ายเงินแล้ว" ? "green" : "red"}
            >
              {payText}
            </Typography>
          </Typography>

          <Typography variant="body1" fontWeight="bold">
            การจัดส่ง:{" "}
            <Typography
              component="span"
              color={shiText === "จัดส่งแล้ว" ? "green" : "red"}
            >
              {shiText}
            </Typography>
          </Typography>

          <Grid container justifyContent="flex-end" spacing={2} mt={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setModalOpenSlip(true)}
              >
                กดเพื่อดูสลิปเงินโอน
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setModalOpenPayment(true)}
              >
                จ่ายเงินแล้ว
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setModalOpenShipping(true)}
              >
                จัดส่งแล้ว
              </Button>
            </Grid>
          </Grid>

          <Typography mt={2} color="error" fontWeight="bold">
            รวม: {productItems?.total?.toLocaleString("th-TH")} บาท
          </Typography>
        </Box>
      </Paper>

      <Box>
        <Typography variant="h6">รายการสั่งซื้อ</Typography>
        {productItems?.item?.map((product: any, index: number) => (
          <Paper key={index} elevation={1} sx={{ padding: 2, marginTop: 2 }}>
            <ShowText label="Product id" description={product.id} />
            <ShowText label="CODE" description={product.code} />
            <ShowText label="ชื่อสินค้า" description={product.name} />
            <ShowText
              label="ราคาต่อชิ้น"
              description={
                product.is_size != "price_by_size"
                  ? product.price
                  : product[product.size] || product.price
              }
            />
            <ShowText label="จำนวน" description={product.quantity} />
            <ShowText
              label="Size"
              description={product?.size?.toUpperCase() || ""}
            />
            <ShowText
              label="จำนวนสินค้าเหลือในคลัง"
              description={product.stock}
            />
          </Paper>
        ))}
      </Box>

      <ConfirmModal
        textHead="คอนเฟิร์มยกเลิกออเดอร์"
        textBody="คุณแน่ใจว่าจะยกเลิก ?"
        isOpen={isModalOpenCancel}
        onClose={() => setModalOpenCancel(false)}
        onConfirm={handleConfirmCancel}
      />
      <ConfirmModal
        textHead="คอนเฟิร์มการได้รับเงิน"
        textBody="คุณแน่ใจว่าได้รับเงินเรียบร้อย ?"
        isOpen={isModalOpenPayment}
        onClose={() => setModalOpenPayment(false)}
        onConfirm={handleConfirmPayment}
      />
      <ConfirmModal
        textHead="คอนเฟิร์มการส่งออเดอร์"
        textBody="คุณแน่ใจว่าจัดส่งออเดอร์แล้ว ?"
        isOpen={isModalOpenShipping}
        onClose={() => setModalOpenShipping(false)}
        onConfirm={handleConfirmShipping}
      />

      {isModalOpenSlip && (
        <Modal open={isModalOpenSlip} onClose={() => setModalOpenSlip(false)}>
          <Box sx={{ ...modalStyle, padding: 4 }}>
            <Typography variant="h6">สลิปเงินโอน</Typography>
            {item?.pay_images ? (
              <img
                src={item?.pay_images?.replace(
                  `{api_path}`,
                  process.env.REACT_APP_API_URL
                )}
                alt="Payment slip"
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <Typography color="textSecondary">
                ไม่ได้แนบสลิปเงินโอน
              </Typography>
            )}
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => setModalOpenSlip(false)}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
}

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

function ShowText({
  label,
  description,
}: {
  label: string;
  description: string;
}) {
  return (
    <Box display="flex" gap={2}>
      <Typography variant="body1" fontWeight="bold">
        {label}:
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
}
