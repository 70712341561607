"use client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Box, CircularProgress, Typography } from "@mui/material";
import { medwholesaleAPI } from "../../api/medwholesale";
import Cookies from "universal-cookie";

// Load the Stripe object
const stripePromise = loadStripe(
  "pk_live_51QAbUw06cpz94vbkvwupnWEXcEIyfwSnUWJZNL4Yg0vFNXcJl4doxDkiJjJKg82043S4de1CeW3d2CxG7OsxhAd6005CG0vEvj"
  // "pk_test_51QAbUw06cpz94vbkv4rFXu3DweJxSvXEE90vcIosXuCfBcxhqyLqXxH9uTBGSZ7CJnqJkiWbb3LQehoLV4QUKIst003S7BS6M6"
);

interface CheckoutFormProps {
  orderId: string; // ประกาศชนิดของ props
  totals: any[];
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ orderId, totals }) => {
  const location = useLocation();
  const state = location.state as { [key: string]: any }; // Add appropriate type if needed
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const cookies = new Cookies();

  const token = cookies.get("access_token");

  // const { fnCreateTransaction } = usePayment();

  const fnCreateTransaction = async (): Promise<any> => {
    // const orderId = "47";
    // if (!orderId) {
    //   throw new Error("Order ID is required to create a transaction.");
    // }
    try {
      const api = new medwholesaleAPI();
      const response = await api.createPayment(token, { orderId, totals });
      return response;
    } catch (err) {
      console.error("Error creating transaction:", err);
      // Handle errors with notifications or retries as needed
      throw err; // Re-throw error to be handled by the caller
    }
  };

  const payment = async () => {
    try {
      const response = await fnCreateTransaction();
      setClientSecret(response.data.clientSecret);
    } catch (err) {
      console.error(err);
      setError("Failed to initiate payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    payment();
  }, []);

  if (loading) {
    // return <CircularIndeterminate />;

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>;
  }

  if (error) {
    return (
      <Box
        sx={{
          padding: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "60vh",
        }}
      >
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box id="checkout" sx={{ padding: 4, height: "100%" }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  );
};

export default CheckoutForm;
