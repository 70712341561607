import React from "react";
import hero from "../image/Home/imghero.png";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaLine } from "react-icons/fa";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import ContactUsNewVer from "../component/contact/ContactUsNewVer";

const CardCustomProd = styled(Box)(({ theme }) => ({
  flexShrink: "0",
  padding: "12px 0",
  fontSize: "24px",
  borderRadius: "16px",
  textAlign: "center",
  margin: "0 auto",
  fontFamily: theme.typography.fontFamily,
  border: "1px solid #DEDEDE",
  position: "relative",
  userSelect: "none",
  [theme.breakpoints.up("xs")]: {
    width: "140px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "220px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "240px",
  },
}));

const TypoGraphyTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textIndent: "12px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
  },
}));
const TypoGraphyHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "0 auto",
  fontWeight: 500,
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
  },
}));

const Containervontact = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  paddingBottom: "24px",
}));
function Contact() {
  return (
    <>
      <Grid sx={{ px: "4%", py:"5%" }} flexDirection="column" gap="24px">
        <Containervontact>
          {/* <Grid className="mt-16" container>
          <CardCustomProd className="font-bold " >CONTACT US</CardCustomProd>  
          </Grid>
          <div className='border border-black relative bottom-14 opacity-5  '>
          </div> */}
          <ContactUsNewVer />

          {/* <Grid
            className="flex-col"
            container
            flexDirection={{ xs: "row", md: "column" }}
            justifyContent="center"
            gap={{ xs: 1, sm: 3 }}
          >
            <Grid
              container
              xs={5}
              md={12}
              flexDirection={{ xs: "column", md: "row", lg: "row" }}
              justifyContent="center"
              gap={{ xs: 1 }}
              alignItems="center"
            >
              <Grid container justifyContent="center">
                <TypoGraphyTitle sx={{ margin: 0 }}></TypoGraphyTitle>
              </Grid>
              <Grid
                className="gap-11 lg:gap-32 w-full "
                container
                justifyContent="center"
                alignItems={"center"}
              >
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/phone.png" alt="" />
                </a>
                <a
                  href="https://line.me/th/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/line.png" alt="" />
                </a>
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/fackbook.png" alt="" />
                </a>
                <a
                  href="http://www.YouTube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20 " src="/youtube.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/instagram.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-10 lg:w-20" src="/googlemap.png" alt="" />
                </a>
              </Grid>
            </Grid>
          </Grid> */}
        </Containervontact>
        {/* <Grid
          container
          className="mt-16 w-full lg:w-full px-16 pb-5 "
          md={"auto"}
          justifyContent="center"
          sx={{
            height: { xs: "200px", sm: "300px", md: "500px", lg: "600px" },
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15494.706465268226!2d100.62448471784592!3d13.858439926050213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d62c8483d2e9f%3A0xef86cec4b9ef36c6!2z4LiV4Lil4Liy4LiU4LiW4LiZ4Lit4Lih4Lih4Li04LiV4Lij!5e0!3m2!1sth!2sth!4v1708631872996!5m2!1sth!2sth"
            width="100%"
            height="100%"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid> */}
      </Grid>
      <></>
    </>
  );
}

export default Contact;
