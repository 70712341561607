import { Box, styled } from '@mui/material';
import { string } from 'prop-types'
import React from 'react'
interface discont {
  discont: string
}
const BoxCustomBand = styled(Box)(({ theme }) => ({
  position: "absolute",
  fontFamily: theme.typography.fontFamily,
  backgroundColor: "#00AAFF",
  borderRadius: "7px",
  color:"white",
  padding:"5px",
//   left:"5px",
  bottom: "0px",
  [theme.breakpoints.up("xs")]: {
    fontSize: '10px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '12px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '10px'
  },
}));
function TagDiscont(prop: discont) {
  return (
    <BoxCustomBand className=' lg:w-[50px]  '>
      <p className='text-xs md:text-sm'>- {prop.discont} %</p>
    </BoxCustomBand>
  )
}

export default TagDiscont