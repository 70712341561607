import * as React from "react";
import {
  Paper,
  Typography,
  TextField,
  Link,
  Button,
  Grid,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import logo from "../image/login/logo.png";
import logo_xs from "../image/login/logo-2.png";
import google from "../image/login/google.png";
import facebook from "../image/login/facebook.png";
import circle from "../image/login/Ellipse 24.png";
import circle2 from "../image/login/Ellipse 25.png";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import { makeStyles } from "@mui/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ClassNames } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const cookies = new Cookies(null, { path: "/" });

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    background: "#094a2a",
    height: "100dvh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:1024px)": {
      height: "100vh",
    },
  },
  paper: {
    margin: "20px",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
  },
  logo: {
    margin: theme.spacing(1),
    zIndex: 2,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    zIndex: 2,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    zIndex: 2,
  },
}));

function Login(components: any) {
  const classes = useStyles();
  const isXs = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (
      cookies.get("token") != null &&
      cookies.get("token") !== "" &&
      cookies.get("token") !== undefined
    ) {
      return navigate("/");
    }
  }, []);

  const notify = (msg: any, options: any) => toast(msg, { ...options });
  const navigate = useNavigate();

  const emailRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);

  const signIn = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (
      email != null &&
      email !== "" &&
      email !== undefined &&
      password != null &&
      password !== "" &&
      password !== undefined
    ) {
      const sendData = {
        email: email,
        password: password,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/members/member/login/`,
        sendData
      );

      if (response.data.message === "Logged in") {
        cookies.set("token", response.data.token);

        notify(response.data.message, {
          duration: 5000,
          style: {
            color: "white",
            backgroundColor: "green",
          },
          icon: "✔",
          position: "bottom-center",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        notify(response.data.message, {
          duration: 5000,
          style: {
            color: "white",
            backgroundColor: "red",
          },
          icon: "❌",
          position: "bottom-center",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
      }
    } else {
      notify("Please fill in complete information.", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  return (
    <Grid container className={classes.root}>
      <Toaster />
      <Grid item xs={12} sm={6} md={4}>
        {!isXs ? (
          <Box className={classes.logo} sx={{ textAlign: "center" }}></Box>
        ) : null}

        <Paper
          elevation={3}
          className={classes.paper}
          sx={{ borderRadius: 3, position: "relative" }}
        >
          {isXs ? <Box className={classes.logo}></Box> : null}
          <IconButton
            onClick={() => navigate("/")}
            sx={{
              color: "#094a2a",
              position: "absolute",
              top: 16,
              left: 16,
              zIndex: 3,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <img src={logo} alt="Logo" style={{ padding: 10 }} />
          <Box className={classes.form}>
            <Typography variant="h4" alignSelf={"self-start"}>
              <Box fontWeight={"600"}>Sign in</Box>
            </Typography>
            <Typography>Email</Typography>
            <TextField
              id="outlined-email-input"
              type="Email"
              autoComplete="current-email"
              fullWidth
              inputRef={emailRef}
            />
            <br></br> <br></br>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Password</Typography>
              <Link
                href="/reset"
                variant="body2"
                sx={{ textDecoration: "none" }}
              >
                Forgot Password?
              </Link>
            </Box>
            <TextField
              fullWidth
              id="outlined-password-input"
              type="Password"
              autoComplete="current-password"
              inputRef={passwordRef}
            />
            <Box textAlign={"center"} sx={{ m: 2 }}>
              <Button
                onClick={() => {
                  signIn();
                }}
                variant="contained"
                color="primary"
                className={classes.submit}
                sx={{ borderRadius: 10, paddingLeft: 4, paddingRight: 4, m: 2 }}
              >
                Sign In
              </Button>
              <Typography
                className="text-red-600"
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 300,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                สมัครสมาชิกรับส่วนลดทันที5%
              </Typography>

              <Link href="/register" sx={{ textDecoration: "none" }}>
                <Typography color={"black"}>
                  <Box component={"span"} sx={{ color: "#ccc" }}>
                    I don't have an account ?
                  </Box>{" "}
                  Sign up
                </Typography>
              </Link>

              <Typography variant="body1" sx={{ m: 2 }}>
                Or continue with
              </Typography>

              <Box sx={{ m: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <img src={google} alt="" />
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <img src={facebook} alt="" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Box sx={{ position: "absolute", left: "10%" }}>
        <img src={circle} alt="" />
      </Box>
      <Box sx={{ position: "absolute", right: "10%", bottom: "5%" }}>
        <img src={circle2} alt="" />
      </Box>
    </Grid>
  );
}

export default Login;
